import React from "react";
import { calendArr, monthStrToArr } from "../../../utils/date";
import MonthWeek from "./MonthWeek";
import cl from "./diarymonth.module.scss";
const Calendar = ({ dataSch, dataNotes, dateRange }) => {
  const [per, st, wArr] = calendArr(dataSch, dateRange);

  const datePl = (pl) => {
    let dt = new Date(st);
    dt.setDate(dt.getDate() + pl);
    return dt;
  };
  return (
    <div className={cl["calendar" + dataSch.direction]}>
      {per.map((el, i) => (
        <MonthWeek
          key={i}
          dataSch={dataSch}
          start={datePl(i * 7)}
          dateRange={monthStrToArr(dateRange)[1]}
          dataNotes={dataNotes}
          arrWeek={el}
          weekNum={wArr[i]}
        />
      ))}
    </div>
  );
};

export default Calendar;
