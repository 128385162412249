import React, { useEffect, useState } from "react";
import clp from "./plan.module.scss";
import { Dropdown } from "react-bootstrap";
import MyModal from "../UI/MyModal";
import MyInputGroup from "../UI/MyInputGroup";
import { BaseAPI } from "../../API/BaseApi";
import { useLoading } from "../../hooks/useLoading";
import { useParams } from "react-router-dom";
import SubjItemsList from "./SubjItemsList";
import OneButton from "../UI/Buttons/OneButton";

const EditBoxPlan = ({ value, ok, esc, del, ...props }) => {
  const params = useParams();
  const [filter, setFilter] = useState(value ? value.subjectName : "");
  const [subjects, setSubjects] = useState([]);
  const [selected, setSelected] = useState(
    !value
      ? {}
      : {
          name: value.subjectName,
          id: value.subjectid,
          color: value.color,
        }
  );
  const [getsubjects, isLoading] = useLoading(async () => {
    const res = await BaseAPI.getSubjects(params.id);
    if (!res) return;
    setSubjects(res);
  });
  useEffect(() => {
    getsubjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelect = (el) => {
    setSelected(el);
    setFilter(el.name);
  };
  const onclick = (e) => {
    if (!selected.name && !filter) return;
    ok(filterList.length === 0 ? { name: filter } : selected);
  };

  const addSubj = async (e) => {
    e.stopPropagation();

    let newid = await BaseAPI.addSubject({
      name: filter,
      scheduleid: params.id,
    });
    setSelected({ name: filter, id: newid });
    setSubjects([...subjects].push({ name: filter, id: newid.id }));
    setFilter("");
  };
  const filterList = !filter
    ? [...subjects]
    : subjects.filter((el) =>
        el.name.toLowerCase().startsWith(filter.toLowerCase())
      );

  return (
    <MyModal
      contentClassName={clp.modaledit}
      dialogClassName={clp.modaledit}
      title="Edit lessons plan"
      setshow={esc}
      show={true}>
      {" "}
      <div className="d-flex">
        <MyInputGroup
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
          style={{ fontSize: "1.5rem" }}
          placeholder="Type to find...">
          {filterList.length === 1 ? (
            <OneButton title="ᐅ" onClick={() => setFilter("")} />
          ) : (
            <OneButton title="ᐁ" onClick={() => setFilter(selected.name)} />
          )}
          <OneButton title="✓" outline={true} onClick={onclick} />
          {del && (
            <OneButton
              title="🗑"
              outline={true}
              onClick={(e) => del(selected)}
            />
          )}
        </MyInputGroup>{" "}
      </div>
      {!filter && (
        <Dropdown.Menu style={{ width: "93%" }} show>
          {!isLoading && (
            <SubjItemsList
              add={addSubj}
              selected={selected}
              onSelect={onSelect}
              list={filterList}
            />
          )}
        </Dropdown.Menu>
      )}
    </MyModal>
  );
};

export default EditBoxPlan;
