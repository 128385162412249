import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../context";
import { Link, useNavigate } from "react-router-dom";
import { BaseAPI } from "../../API/BaseApi";
import cllog from "./login.module.css";
import OneButton from "../UI/Buttons/OneButton";
const Login = () => {
  const router = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const { userAuth, setUserAuth } = useContext(AuthContext);
  const [email, setEmail] = useState("test@test.test");
  const [password, setPassword] = useState("test123456");

  const login = async (event) => {
    try {
      let response = await BaseAPI.login(email, password);
      setUserAuth({ isAuth: true, role: response.role });
      router(`/manager`);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div className={cllog.page}>
      <br />
      <div className={cllog["box-form"]}>
        <div className={cllog["left"]}>
          <div className={cllog["overlay"]}>
            <h1>Hello there!</h1>
            <div>
              For using this services you need to create an acount. Sign in to
              join us! Make your own lessons schedule. Add notes and never
              forget what needs to be done!
            </div>
          </div>{" "}
          <br />
        </div>

        <div className={cllog["right"]}>
          <h5>Login</h5>
          <div className="mt-5">
            Don't have an account?{" "}
            <Link className=" text-success" to="/signup">
              Creat Your Account
            </Link>{" "}
            it takes less than a minute
          </div>
          <div className={cllog["inputs"]}>
            <input
              className={cllog["login_input"]}
              type="text"
              placeholder="user name"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />
            <input
              className={cllog["login_input"]}
              type="password"
              placeholder="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <br />

          <OneButton
            outline={true}
            title="Login"
            className={cllog.btns}
            onClick={login}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
