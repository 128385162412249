import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const MyInputGroup = ({ children, cl, cn, label, ...props }) => {
  return (
    <InputGroup className={cn ? cn : "mb-1"} size={props.size} style={cl}>
      {label && (
        <InputGroup.Text
          className={props.labelclass ? props.labelclass : ""}
          style={props.labelstyle}>
          {label}
        </InputGroup.Text>
      )}
      <Form.Control {...props} aria-label={props.placeholder} />

      {children}
    </InputGroup>
  );
};

export default MyInputGroup;
