import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

const MyFloatingLabel = ({ label, ...props }) => {
  return (
    <div>
      <FloatingLabel label={label} className="mb-3">
        <Form.Control {...props} />
      </FloatingLabel>
    </div>
  );
};

export default MyFloatingLabel;
