import React from "react";
import { BaseAPI } from "../../API/BaseApi";
import MyDropDownMenu from "../UI/MyDropDownMenu/MyDropDownMenu";
import WeeksNumber from "../Plan/WeeksNumber";

const PlanNav = ({ title, mode, switchWeek, upd }) => {
  const fillByCopy = async (weekNumberFrom) => {
    if (!window.confirm("Delete all lessons?")) return;
    await BaseAPI.deleteAllLesson(title.id, mode.week);
    await BaseAPI.copyLessons(title.id, weekNumberFrom, mode.week);
    await upd();
  };
  const menu = () => {
    let arr = [
      {
        name: "clear week " + mode.week,
        onClick: () => {
          if (!window.confirm("Delete all lessons?")) return;
          BaseAPI.deleteAllLesson(title.id, mode.week);
          upd();
        },
      },
    ];
    if (title.weeksNumber.weeks < 2) return arr;
    arr.push("Divider");
    for (let i = 1; i < title.weeksNumber.weeks + 1; i++) {
      if (i !== mode.week)
        arr.push({
          name: "copy week " + i,
          onClick: () => {
            fillByCopy(i);
          },
        });
    }
    return arr;
  };

  return (
    <div className="string_submenu" style={{ zIndex: "900" }}>
      <WeeksNumber title={title} mode={mode} switchWeek={switchWeek} />
      <MyDropDownMenu arr={menu()} title="..." className="h-100" />
    </div>
  );
};

export default PlanNav;
