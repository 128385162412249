import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import OneButton from "../../UI/Buttons/OneButton";
import MyInputGroup from "../../UI/MyInputGroup";
import MyModal from "../../UI/MyModal";
import DataGroup from "./DataGroup";
import DaysGroupRow from "./DaysGroupRow";
import TimeGroup from "./TimeGroup";
import WeekNumbers from "./WeekNumbers";
import cl from "./editSchedule.module.scss";

const EditSchedule = ({ esc, ok, data }) => {
  const [dataForm, setDataForm] = useState(data);

  const titleBtn = (
    <div className={cl.titleform}>
      <OneButton
        title="Save settings"
        outline={true}
        onClick={() => ok(dataForm)}
      />{" "}
      {dataForm.name}
    </div>
  );
  return (
    <MyModal title={titleBtn} setshow={esc} show={true}>
      <Form className="mb-1">
        <MyInputGroup
          required
          size="sm"
          label="Schedule name:"
          placeholder="Schedule name"
          value={dataForm.name}
          onChange={(e) =>
            setDataForm({ ...dataForm, name: e.target.value.slice(0, 30) })
          }
        />{" "}
        <WeekNumbers dataForm={dataForm} setDataForm={setDataForm} />
        <DaysGroupRow dataForm={dataForm} setDataForm={setDataForm} />
        <DataGroup dataForm={dataForm} setDataForm={setDataForm} />
        <div className="d-flex">
          <TimeGroup dataForm={dataForm} setDataForm={setDataForm} />
        </div>
      </Form>
    </MyModal>
  );
};

export default EditSchedule;
// "#563d7c"s
