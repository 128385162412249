import React from "react";
import { getUserWeek } from "../../utils/date";
import LessNum from "./LessNum";
import Day from "./Day";
import cl from "./plan.module.scss";

const Week = ({ data, drag, update, del, switchMode }) => {
  const userWeek = getUserWeek(data.sch);
  return (
    <div className={`${cl.schedule} ${data.mode === 1 ? cl.wrap : cl.nowrap} `}>
      <LessNum mode={data.mode} sch={data.sch} switchMode={switchMode} />
      {userWeek.map((item) => (
        <Day
          key={item}
          data={{
            ...data,
            lessons: data.lessons[item] ? data.lessons[item] : [],
            day: item,
          }}
          update={update}
          del={del}
          drag={drag}
        />
      ))}
    </div>
  );
};

export default Week;
