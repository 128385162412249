import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import EditSchedule from "./EditSchedule/EditSchedule";
import { BaseAPI } from "../../API/BaseApi";
import OneButton from "../UI/Buttons/OneButton";
import cl from "./schedule.module.scss";
import { GrScheduleNew } from "react-icons/gr";
import { ImCancelCircle } from "react-icons/im";

const SchedulesList = ({ list, refresh }) => {
  const [edit, setEdit] = useState(false);
  const delSchedule = async (id) => {
    if (!window.confirm("Delete the schedule?")) return;
    await BaseAPI.deleteSchedule(id);
    refresh();
  };
  const router = useNavigate();

  const editS = () => {
    setEdit(false);
  };
  const update = async (newD) => {
    await BaseAPI.updateSchedule(edit.id, newD);
    setEdit(false);
    refresh();
  };
  const openSchedule = (id, name) => {
    router(`/schedules/${id}`);
  };
  return (
    <>
      {edit && (
        <div>
          <EditSchedule ok={update} esc={editS} data={edit} />
        </div>
      )}
      <div className="marginUp5 d-flex flex-wrap justify-content-center ">
        {list.map((item, i) => (
          <div
            className={cl.scheduleContainer}
            onClick={(e) => {
              e.stopPropagation();
              openSchedule(item.id, item.name);
            }}>
            <div
              className={cl.scheduleTitle}
              onClick={(e) => {
                e.stopPropagation();
                setEdit(item);
              }}>
              {item.name}
            </div>
            <div className={cl.scheduleBody}>
              <OneButton
                title=<ImCancelCircle />
                outline={true}
                onClick={(e) => {
                  e.stopPropagation();
                  delSchedule(item.id);
                }}
                variant="outline-success"
                className="schlist-btn"
              />
              <OneButton
                title=<GrScheduleNew />
                outline={true}
                onClick={(e) => {
                  e.stopPropagation();
                  setEdit(item);
                }}
                variant="outline-success"
                className="schlist-btn"
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default SchedulesList;
