import React, { useMemo } from "react";
import { DayNote, noteById } from "../../../utils/arraysFunc";
import ColorDot from "../../UI/ColorDot/ColorDot";
import LessonBoxC from "./LessonBoxC";
import cl from "../diary.module.scss";
import { useState } from "react";
import OneDayModal from "../OneDayModal";
import clmonth from "./diarymonth.module.scss";
import { weekDayNames } from "../../../utils/date";

const MonthDay = ({ arrDay, dataSch, dataNotes, dates, weekNum }) => {
  const [showDay, setShowDay] = useState(false);

  const onedaynote = useMemo(
    () => DayNote(dataNotes.notes, dates.day[0]),
    [dataNotes.notes, dates.day]
  );
  const dayTitle = useMemo(
    () =>
      dates.day[0].toISOString().slice(0, 10) +
      (dataSch.direction === "row"
        ? " " + weekDayNames[dates.day[0].getDay()]
        : dates.day[1]
        ? " TODAY"
        : ""),
    [dataSch.direction, dates.day]
  );
  const inMonth = Number(dates.month) === Number(dates.day[0].getMonth() + 1);

  const headerClassName = useMemo(() => {
    const arrstyle = [
      cl["dayCHeadMode" + dataSch.mode],
      clmonth["dayheader" + dataSch.direction],
    ];
    if (dates.day[1]) arrstyle.push(clmonth["today" + dataSch.direction]);
    if (inMonth) arrstyle.push("fw-bold");
    return arrstyle.join(" ");
  }, [dataSch.direction, dataSch.mode, dates.day, inMonth]);

  const arrMax = useMemo(() => {
    let maxLessons = dataSch.lessons[dates.day[0].getDay()].reduce(
      (max, item) => Math.max(max, item.length),
      0
    );
    return Array(maxLessons).fill(1);
  }, [dataSch.lessons, dates.day]);

  const classWrap = useMemo(() => {
    const arrstyle = [
      "mb-1",
      clmonth.dayWrap,
      clmonth["card" + dataSch.direction],
    ];
    if (!inMonth) arrstyle.push(" text-muted");
    return arrstyle.join(" ");
  }, [dataSch.direction, inMonth]);

  const getOneNote = (i) =>
    arrDay[i] === null ? "" : noteById(onedaynote, arrDay[i].id, dates.day[0]);

  return (
    <>
      {showDay ? (
        <OneDayModal
          setVisible={setShowDay}
          dataSch={{ ...dataSch, day: dates.day[0].getDay(), mode: 1 }}
          date={dates.day[0]}
          weekNum={weekNum}
          notes={dataNotes.notes}
        />
      ) : (
        <div className={classWrap}>
          <div className={headerClassName} onClick={() => setShowDay(!showDay)}>
            {dayTitle}
            <sup className="fw-light">week{weekNum}</sup>
            {!onedaynote.length || <ColorDot />}
          </div>
          {dataSch.mode === 0 && (
            <div className={cl.bodyBox}>
              {arrDay &&
                arrMax.map((_, i) =>
                  arrDay.length > i ? (
                    <LessonBoxC
                      key={i}
                      note={getOneNote(i)}
                      item={arrDay[i]}
                      day={dates.day[0]}
                      i={i}
                      update={dataNotes.getNotes}
                      weekstyle={dataSch.direction}
                    />
                  ) : (
                    <div key={i} className={cl["lessBoxCempty"]}>
                      🞨
                    </div>
                  )
                )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MonthDay;
