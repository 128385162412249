import Login from "../components/users/Login";
import Schedules from "../components/Schedule/Schedules";
import SignUp from "../components/users/SignUp";
import Profile from "../components/users/Profile";
import OneSchedule from "../components/OneSchedule/OneSchedule";

export const privateRoutes = [
  { path: "/schedules", element: <Schedules />, nameNav: "My Schedules" },
  { path: "/schedules/:id", element: <OneSchedule /> },
  { path: "/profile", element: <Profile /> },
  { path: "*", element: <Schedules /> },
];
export const publicRoutes = [
  { path: "*", element: <Login /> },
  { path: "/login", element: <Login />, nameNav: "Login" },
  { path: "/signup", element: <SignUp /> },
];
