import React from "react";
import Modal from "react-bootstrap/Modal";

const MyModal = ({
  children,
  title = "",
  subtitle = "",
  callback,
  setshow,
  show,
  headerCh = "",
  ...props
}) => {
  return (
    <Modal
      show={show}
      onHide={() => {
        if (callback) callback();
        setshow(false);
      }}
      className="h-100"
      {...props}>
      <Modal.Header closeButton>
        <Modal.Title className="text-muted fw-lighter fs-6">
          {title}
        </Modal.Title>
      </Modal.Header>

      {subtitle && (
        <div className="d-flex justify-content-center align-items-center">
          <h5 className="text-center">{subtitle}</h5>
        </div>
      )}
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default MyModal;
