import React from "react";
import MonthDay from "./MonthDay";
import cl from "./diarymonth.module.scss";

const MonthWeek = ({
  arrWeek,
  dataSch,
  dataNotes,
  start,
  dateRange,
  weekNum,
}) => {
  const datePl = (pl) => {
    const today = new Date();
    today.setHours(12, 0, 0, 0);
    let dt = new Date(start);
    dt.setDate(dt.getDate() + pl);
    return [dt, today.getTime() === dt.getTime()];
  };

  return (
    <div className={cl["week" + dataSch.direction]}>
      {arrWeek.map(
        (item, i) =>
          item && (
            <MonthDay
              key={i}
              weekNum={weekNum}
              dataSch={dataSch}
              dataNotes={dataNotes}
              arrDay={item}
              dates={{ month: dateRange, day: datePl(i) }}
            />
          )
      )}
    </div>
  );
};

export default MonthWeek;
