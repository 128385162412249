import React from "react";
import { titleArrForDiary } from "../../../utils/arraysFunc";
import { weekDayNames } from "../../../utils/date";
import cl from "../diary.module.scss";

const WeekDayTitle = ({ dataSch }) => {
  const arrTitle = titleArrForDiary(dataSch.lessons);

  return (
    <div bg="Light" dataday={dataSch.day} className={cl.wraptitleDay}>
      <div
        className={cl.dayCHeadMode1}
        onClick={() => {
          dataSch.setMode(1 - dataSch.mode);
        }}>
        {weekDayNames[dataSch.day]}
      </div>
      {dataSch.mode === 0 && (
        <div className={cl.lessNameDay1}>
          {arrTitle.map((el, i) => (
            <div key={i} className={cl.lessNameWeek1}>
              {el.map((less, num) => (
                <div
                  key={num}
                  className={cl.lessName1}
                  style={{
                    backgroundColor: less.color,
                  }}>
                  <span className="w-25">{i + 1}</span>
                  <span>
                    {less.subjectName}
                    <sup>{less.weekT}</sup>
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default WeekDayTitle;
