import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { AuthContext } from "./context";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import "./styles/App.scss";
import "./styles/animations.css";
import { BaseAPI } from "./API/BaseApi";

function App() {
  // const [isAuth, setIsAuth] = useState(false);
  const [userAuth, setUserAuth] = useState({ isAuth: false, role: null });
  const checkUserAuth = async () => {
    try {
      const user = await BaseAPI.getUser();
      if (user) setUserAuth({ isAuth: true, role: user.role });
    } catch (error) {}
  };

  useEffect(() => {
    // BaseAPI.createDB();
    checkUserAuth();
  }, []);
  return (
    <AuthContext.Provider value={{ userAuth, setUserAuth }}>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;
