// import * as fbHelpers from "../utils/serverFireBaseHlp/fbHelpers";
import axios from "axios";
import { defineCurrentWeek } from "../utils/date";
import imgProfile from "../img/profile.ico";
export const BaseAPI = {
  async getAuthHeaders() {
    let token = JSON.parse(localStorage.getItem("tokenschedule"));
    if (!token) throw new Error("session not found");
    return {
      "Authorization": `Bearer ${token}`,
    };
  },
  //{method:, url:, isHeader: true, data: "", params :""}
  async serverReq(method, url, isHeader = true, data = "", params = "") {
    // async serverReq(method, url, isHeader = true, data = "", params = "") {
    let axiosConfig = {
      method: method,
      // url: "http://localhost:9000" + url,
      // url: "http://34.214.160.243:9000" + url,
      url: "https://api.learnapp.me/service2" + url,
    };
    if (params) axiosConfig.params = params;
    if (data) axiosConfig.data = { data: data };
    if (isHeader) axiosConfig.headers = await this.getAuthHeaders();

    try {
      let result = await axios(axiosConfig);
      // if ((method = "get")) return result.resultData;
      // if ((method = "post" && result.resultData)) return result.resultData;
      return {
        status: true,
        message: "success",
        data: result.data.resultData ? result.data.resultData : "",
      };
    } catch (error) {
      if (error.code === "ERR_NETWORK") return { error: error.message };
      return { error: error.response.data.error };
    }
  },
  //_________________________________________________SCHEDULES
  //create schedule post/schedules
  async createSchedule(listData) {
    let reqData = {
      name: listData.name ? listData.name : "",
      note: listData.note ? listData.note : "",
      lessonDuration: listData.lessonDuration,
      workDays: listData.workDays,
      weeksNumber: listData.weeksNumber,
      timeTable: listData.timeTable,
      maxLessons: listData.maxLessons,
      startDay: listData.startDay,
    };

    let result = await this.serverReq("post", "/schedules", true, reqData);
    if (result.error) throw new Error(result.error);
    return result.data;
  },
  //delete schedule delete/schedules/:id
  async deleteSchedule(id) {
    let reqParams = { id: id };
    let result = await this.serverReq(
      "delete",
      "/schedules/" + id,
      true,
      "",
      reqParams
    );
    if (result.error) throw new Error(result.error);

    return result.data;
  },
  //get one schedule by id   get/schedules/:id
  async getSchedule(id) {
    let reqParams = { id: id };
    let result = await this.serverReq(
      "get",
      "/schedules/" + id,
      true,
      "",
      reqParams
    );

    if (result.error) throw new Error(result.error);

    let weeksNumbercurrent = defineCurrentWeek(
      JSON.parse(result.data.weeksNumber),
      result.data.startDay
    );
    return {
      ...result.data,
      timeTable: JSON.parse(result.data.timeTable),
      workDays: JSON.parse(result.data.workDays),
      weeksNumber: weeksNumbercurrent,
    };
  },
  //get one schedule by id   get/schedules
  async getScheduleAll() {
    let result = await this.serverReq("get", "/schedules");
    if (result.error) throw new Error(result.error);
    if (!result.data) return [];

    let res = result.data.map((el) => {
      let weeksNumbercurrent = defineCurrentWeek(
        JSON.parse(el.weeksNumber),
        el.startDay
      );
      return {
        ...el,
        timeTable: JSON.parse(el.timeTable),
        workDays: JSON.parse(el.workDays),
        weeksNumber: weeksNumbercurrent,
      };
    });

    return res;
  },
  //update schedule post/schedules/:id
  async updateSchedule(id, listData) {
    let reqParams = { id: id };
    let reqData = {
      name: listData.name ? listData.name : "",
      note: listData.note ? listData.note : "",
      lessonDuration: listData.lessonDuration,
      workDays: listData.workDays,
      weeksNumber: listData.weeksNumber,
      timeTable: listData.timeTable,
      maxLessons: listData.maxLessons,
      startDay: listData.startDay,
    };

    let result = await this.serverReq(
      "patch",
      "/schedules/" + id,
      true,
      reqData,
      reqParams
    );
    if (result.error) throw new Error(result.error);
    return result.data;
  },
  //_________________________________________________ LESSONS
  //create new lesson post/schedule/:id/lesson
  async createLesson(name, listid, num, day, week, subid = "") {
    let reqParams = { id: listid };
    if (
      listid === undefined ||
      subid === undefined ||
      !name ||
      num === undefined ||
      day === undefined ||
      week === undefined
    )
      return;
    let reqData = {
      scheduleid: listid,
      subjectid: subid,
      subjectName: name,
      ordNum: num,
      weekDay: day,
      weekNumber: week,
    };
    let url = "/schedules/" + listid + "/lessons";
    let result = await this.serverReq("post", url, true, reqData, reqParams);
    if (result.error) throw new Error(result.error);
    return result.data;
  },
  //delete one lesson delete/lesson/:id
  async deleteLesson(itemId) {
    let reqParams = { id: itemId };
    let result = await this.serverReq(
      "delete",
      "/lessons/" + itemId,
      true,
      "",
      reqParams
    );
    if (result.error) throw new Error(result.error);
  },
  //delete all lesson delete/lessons/all
  async deleteAllLesson(scheduleid, weekNumber = 0) {
    let reqParams = { scheduleid: scheduleid };
    if (weekNumber) reqParams.weekNumber = weekNumber;
    let result = await this.serverReq(
      "delete",
      "/lessons/all",
      true,
      "",
      reqParams
    );
    if (result.error) throw new Error(result.error);
  },
  //get lesson by scheduleid get/schedule/:id/lessons
  async getLessons(scheduleid) {
    let reqParams = { id: scheduleid };
    let url = "/schedules/" + scheduleid + "/lessons";
    let result = await this.serverReq("get", url, true, "", reqParams);
    if (result.error) throw new Error(result.error);
    return result.data;
  },
  //get lesson by scheduleid post/schedule/:id/lessons
  async getLessonsByWeek(scheduleid, weekNumber) {
    let reqParams = { id: scheduleid, weekNumber: weekNumber };
    let url = "/schedules/" + scheduleid + "/lessons/weeks";
    let result = await this.serverReq("get", url, true, "", reqParams);
    if (result.error) throw new Error(result.error);
    return result.data;
  },

  //update lesson by id patch/lessons/:id
  async copyLessons(scheduleid, weekNumberFrom, weekNumberTo) {
    let reqData = {
      scheduleid: scheduleid,
      weekNumberFrom: weekNumberFrom,
      weekNumberTo: weekNumberTo,
    };

    let result = await this.serverReq("patch", "/lessons/copy", true, reqData);
    if (result.error) throw new Error(result.error);
    return result.data;
  },
  //update lesson by id patch/lessons/:id
  async updateLesson(el, newName = "") {
    let reqParams = { id: el.id };
    let reqData = {
      ordNum: el.ordNum,
      weekDay: el.weekDay,
      scheduleid: el.scheduleid,
    };
    if (newName) reqData.subjectName = newName;
    let result = await this.serverReq(
      "patch",
      "/lessons/" + el.id,
      true,
      reqData,
      reqParams
    );
    if (result.error) throw new Error(result.error);
    return result.data;
  },
  //update lesson by id patch/lessons/
  async updateLessonsArr(updArr) {
    // for reordering
    let reqData = updArr;
    let result = await this.serverReq("patch", "/lessons", true, reqData);
    if (result.error) throw new Error(result.error);
    return result.data;
  },
  //_________________________________________________SUBJECTS
  //get lesson by scheduleid post/schedule/:id/subjects
  async getSubjects(scheduleid) {
    let reqParams = { id: scheduleid };
    let url = "/schedules/" + scheduleid + "/subjects";
    let result = await this.serverReq("get", url, true, "", reqParams);
    if (result.error) throw new Error(result.error);
    return result.data;
  },
  //add new subject
  async addSubject(set) {
    let url = "/subjects/";
    let reqData = { ...set };
    let result = await this.serverReq("post", url, true, reqData);
    if (result.error) throw new Error(result.error);
    return result.data;
  },
  //delete one subject delete/subjects/:id
  async deleteSubject(el) {
    let reqParams = { id: el.id };
    let url = "/subjects/" + el.id;
    let result = await this.serverReq("delete", url, true, "", reqParams);
    if (result.error) throw new Error(result.error);

    // let list = await this.getLessons(el.listid);

    // indbase.sort((a, b) => a.num - b.num);
    // indbase.sort((a, b) => a.day - b.day);

    return result.data;

    // await this.toLS("Subjects", indbase);

    // list = await this.fromLS("Content");

    // indbase = list.filter(
    //   (item) => item.subjectid.toString() !== el.id.toString()
    // );
    // if (indbase.length === list.length) return;
    // await this.toLS("Content", indbase);

    // list = await this.getListContent(el.listid);
    // indbase.sort((a, b) => a.num - b.num);
    // indbase.sort((a, b) => a.day - b.day);

    // let day = 0;
    // let i = 0;
    // let updarr = [];
    // indbase.forEach((element) => {
    //   if (day !== element.day) {
    //     i = 0;
    //     day = element.day;
    //   }
    //   if (element.num !== i) updarr.push({ ...element, num: i });
    //   i++;
    // });

    // await this.updateContentArr(updarr);
  },

  async updateSubject(id, newSet) {
    let reqParams = { id: id };
    let reqData = { ...newSet };
    let result = await this.serverReq(
      "patch",
      "/subjects/" + id,
      true,
      reqData,
      reqParams
    );
    if (result.error) throw new Error(result.error);
    return result.data;
  },
  //_________________________________________________NOTES
  //create note post/notes
  async createNote(note, scheduleid, lessonid, date) {
    let reqData = {
      scheduleid: scheduleid,
      lessonid: lessonid,
      date: date.getTime(),
      note: note.note,
    };
    let url = "/notes";
    let result = await this.serverReq("post", url, true, reqData);
    if (result.error) throw new Error(result.error);
    return result.data;
  },
  //delete one note delete/subjects/:id
  async deleteNote(id) {
    let reqParams = { id: id };
    let url = "/notes/" + id;
    let result = await this.serverReq("delete", url, true, "", reqParams);
    if (result.error) throw new Error(result.error);
    return result.data;
  },

  //get notes by scheduleid and dates get/notes
  async getNotesByDate(date1, date2, scheduleid) {
    let dt1 = date1.getTime(); //to servertime
    let dt2 = date2.getTime(); //to servertime

    let reqData = {
      scheduleid: scheduleid,
      start: dt1,
      end: dt2,
    };
    let url = "/notes";
    let result = await this.serverReq("get", url, true, "", reqData);
    if (result.error) throw new Error(result.error);

    return result.data;
  },
  //create note post/notes
  async updateNote(noteid, note) {
    let reqParams = { id: noteid };
    let reqData = {
      note: note,
    };
    let url = "/notes/" + noteid;
    let result = await this.serverReq("patch", url, true, reqData, reqParams);
    if (result.error) throw new Error(result.error);
    return result.data;
  },
  //_________________________________________________USERS
  async createUser(ud) {
    let reqData = {
      email: ud.email,
      password: ud.password,
      name: ud.name,
      img: ud.img,
    };

    return await this.serverReq("post", "/users", false, reqData);
  },

  async getUser() {
    let result = await this.serverReq("get", "/users", true);
    if (result.error) throw new Error(result.error);
    if (!result.data.img) result.data.img = imgProfile;
    let usrData = { ...result.data, password: "" };
    return usrData;
  },
  async login(login, passw) {
    let reqData = {
      email: login,
      password: passw.toString(),
    };
    let result = await this.serverReq("post", "/users/login", false, reqData);
    if (result.error) throw new Error(result.error);

    let token = result.data.token;
    localStorage.setItem("Auth", "true");
    localStorage.setItem("tokenschedule", JSON.stringify(token));
    return { status: true, role: result.role };
  },
  async logout() {
    let result = await this.serverReq("delete", "/users/logout", true);
    if (!result.error) {
      localStorage.setItem("Auth", "false");
      localStorage.removeItem("tokenschedule");
    }
    return result;
  },
  async updateUser(ud) {
    let reqData = { ...ud };
    return await this.serverReq("patch", "/users", true, reqData);
  },
  getAvatarUrlList(num) {
    const avlist = JSON.parse(localStorage.getItem("avatars"));

    return avlist;
  },
};
