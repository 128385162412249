import React, { useState } from "react";
import OneButton from "../UI/Buttons/OneButton";
import cl from "./MyNavbar.module.scss";
import EditSchedule from "../Schedule/EditSchedule/EditSchedule";
import { BaseAPI } from "../../API/BaseApi";

const BtnMySchedule = ({ setSchedule, schedule }) => {
  const [edit, setEdit] = useState(false);
  const editS = () => {
    setEdit(!edit);
  };

  const update = async (newD) => {
    setEdit(!edit);
    await BaseAPI.updateSchedule(schedule.id, newD);
    setSchedule(newD);
  };
  console.log(schedule);

  return (
    <>
      {edit && <EditSchedule ok={update} esc={editS} data={schedule} />}{" "}
      <OneButton
        outline={true}
        onClick={editS}
        className={cl.btn_myschedule}
        title={schedule.name + "📋"}
      />
    </>
  );
};

export default BtnMySchedule;
