import React, { useEffect, useState } from "react";
import BtnsNav from "../Nav/BtnsNav";
import { useParams } from "react-router-dom";
import { BaseAPI } from "../../API/BaseApi";
import Plan from "../Plan/Plan";
import { useLoading } from "../../hooks/useLoading";
import BtnMySchedule from "../Nav/BtnMySchedule";
import DiaryNotes from "./DiaryNotes";

const OneSchedule = () => {
  const [page, setPage] = useState("plan");
  const [schedule, setSchedule] = useState(null);

  const id = useParams().id;
  const [getSchedule, isLoading] = useLoading(async () => {
    const res = await BaseAPI.getSchedule(id);
    setSchedule(res);
  });

  useEffect(() => {
    getSchedule();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {!isLoading && schedule && (
        <>
          <div
            className="string_menu"
            style={{ zIndex: "0", position: "absolute" }}>
            <BtnsNav page={page} setPage={setPage} />
            <BtnMySchedule schedule={schedule} setSchedule={setSchedule} />
          </div>
          <div>
            {page}
            {page === "plan" ? (
              <Plan schedule={schedule} setScedule={setSchedule} />
            ) : (
              <DiaryNotes schedule={schedule} page={page} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default OneSchedule;
