import React from "react";
import { BaseAPI } from "../../API/BaseApi";
import { noteById } from "../../utils/arraysFunc";
import { weekDayNames } from "../../utils/date";
import MyModal from "../UI/MyModal";
import InputNote from "./InputNote";

const OneDayModal = ({ setVisible, dataSch, notes, date, weekNum }) => {
  const createNote = async (newNote, less) => {
    await BaseAPI.createNote({ note: newNote }, less.scheduleid, less.id, date);
  };

  return (
    <MyModal
      callback={notes.getNotes}
      fullscreen={true}
      title={weekDayNames[dataSch.day] + ": " + date.toISOString().slice(0, 10)}
      setshow={setVisible}
      show={true}>
      <div>
        <div>
          {dataSch.lessons[dataSch.day][weekNum - 1] &&
            dataSch.lessons[dataSch.day][weekNum - 1].map((item, i) => (
              <InputNote
                key={i}
                less={
                  item === null ? { color: "#F5F5F5", subjectName: "🞨" } : item
                }
                note={item === null ? "" : noteById(notes, item.id, date)}
                createNote={createNote}
              />
            ))}
        </div>
      </div>
    </MyModal>
  );
};

export default OneDayModal;
