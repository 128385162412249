import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import MyNavbar from "./Nav/MyNavbar";

const AppRouter = () => {
  // eslint-disable-next-line no-unused-vars
  const [routesArr] = useAuth(true);

  return (
    <div>
      <MyNavbar />
      <div className="mt-5">
        <Routes>
          {routesArr.map((item, i) => (
            <Route path={item.path} element={item.element} key={i} />
          ))}
        </Routes>
      </div>
    </div>
  );
};

export default AppRouter;
