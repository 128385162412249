import React from "react";

import cl from "./sidebar.module.css";

const Sidebar = ({ children }) => {
  return (
    <div className={cl.sideBarMain}>
      <div className={cl.sidebarBody}>
        <div style={{ width: "100%" }}>{children}</div>
      </div>
    </div>
  );
};

export default Sidebar;
