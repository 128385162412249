import React, { useRef, useState, useEffect } from "react";
import { useLoading } from "../../hooks/useLoading";
import { addMoveEl, delEl, moveEl } from "../../utils/dragDrop";
import Week from "./Week";
import Settings from "./Settings";
import PlanNav from "../Nav/PlanNav";
import { BaseAPI } from "../../API/BaseApi";
import { CSSTransition } from "react-transition-group";
import { delLessPlanBySubject } from "../../utils/arraysFunc";
import clp from "./plan.module.scss";

const Plan = ({ schedule }) => {
  const [mode, setMode] = useState({ view: "box", week: 1 });
  const [lessons, setLessons] = useState([]);
  const dragTo = useRef();
  const dragFrom = useRef();
  const [getLess, isLoading] = useLoading(async (wk) => {
    const res = await BaseAPI.getLessonsByWeek(
      schedule.id,
      wk ? wk : mode.week
    );
    setLessons(res);
  });

  const del = (item) => {
    delEl(item, lessons, setLessons);
  };
  const move = () => {
    if (
      dragTo.current.weekDay !== dragFrom.current.weekDay &&
      lessons[dragTo.current.weekDay].length === schedule.maxLessons
    )
      return;

    moveEl(dragFrom, dragTo, lessons, setLessons);
  };
  const addMove = () => {
    addMoveEl(dragTo, dragFrom, schedule.id, mode.week, setLessons, lessons);
  };

  useEffect(() => {
    // getSchedule();
    getLess();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const switchMode = () => {
    setMode({ ...mode, view: mode.view === "box" ? "card" : "box" });
  };
  const switchWeek = async (week) => {
    setMode({ ...mode, week: week });
    await getLess(week);
  };

  const setlesscolor = (color, subjid) => {
    let newarr = lessons.map((el) =>
      el.map((item) =>
        item.subjectid === subjid ? { ...item, color: color } : item
      )
    );
    setLessons(newarr);
  };
  const delLessSubg = (subjid) => {
    let newarr = delLessPlanBySubject(lessons, subjid);
    setLessons(newarr);
  };
  return (
    <>
      <CSSTransition appear={true} in={true} timeout={700} classNames="page">
        <div style={{ zIndex: "300", position: "relative" }}>
          <PlanNav
            title={schedule}
            mode={mode}
            switchWeek={switchWeek}
            upd={getLess}
          />{" "}
          <br />
          <div className={clp.planWrap}>
            {!isLoading && schedule && (
              <>
                <Week
                  switchMode={switchMode}
                  data={{
                    lessons: lessons,
                    mode: mode,
                    sch: schedule,
                  }}
                  update={{
                    getLess: getLess,
                    setLess: setLessons,
                  }}
                  del={del}
                  drag={{ move: move, dragFrom: dragFrom, dragTo: dragTo }}
                />
              </>
            )}
            <Settings
              drag={{ move: addMove, dragFrom: dragFrom, dragTo: dragTo }}
              getLess={getLess}
              setlesscolor={setlesscolor}
              delLessSubg={delLessSubg}
            />
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Plan;
