/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Image from "react-bootstrap/Image";
import MyModal from "../UI/MyModal";
import { useLoading } from "../../hooks/useLoading";

import MySpinner from "../UI/MySpinner";
import Form from "react-bootstrap/Form";
import ProfileImg from "../../img/profile.ico";
import cl from "./users.module.css";
import { BaseAPI } from "../../API/BaseApi";
import OneButton from "../UI/Buttons/OneButton";

const AvatarGalery = ({ setVisible, fileChange }) => {
  const [avatarUrlList, setAvatarUrlList] = useState([]);
  const [choice, setChoice] = useState(ProfileImg);
  const [getAvatarList, isLoading] = useLoading(async () => {
    setAvatarUrlList(await BaseAPI.getAvatarUrlList());
  });

  useEffect(() => {
    getAvatarList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultImg = () => {
    setChoice(ProfileImg);
  };

  const fromFile = (e) => {
    let img = e.target;
    const [file] = img.files;
    if (file) {
      setChoice(URL.createObjectURL(file));
    }
  };

  const fromGallery = () => {
    fileChange(choice);
    setVisible(false);
  };

  return (
    <MyModal
      title={"Import from file"}
      subtitle={"Сlick on the picture or choose your own"}
      show={true}
      setshow={setVisible}>
      <div className="d-flex mt-3">
        <Form.Control type="file" onChange={fromFile} size="lg" />
      </div>

      <div className="d-flex p-2 flex-wrap justify-content-between">
        <div className={cl.previewBlock}>
          <Image rounded src={choice} className={cl.imgPreview} />
          <div className={cl.btn}>
            <OneButton title="CLEAR" onClick={defaultImg} />{" "}
            <OneButton title="SELECT" onClick={fromGallery} />{" "}
          </div>
        </div>

        {isLoading ? (
          <MySpinner />
        ) : (
          <>
            {avatarUrlList.map((elem) => (
              <Image
                key={elem.name}
                className={cl.imgGallary}
                src={elem.url}
                onClick={(e) => {
                  setChoice(elem.url);
                }}
              />
            ))}
          </>
        )}
      </div>
    </MyModal>
  );
};

export default AvatarGalery;
