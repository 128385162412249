import React from "react";
import { durationShift, NewTimeArr } from "../../../utils/timeStr";
import MyInputGroup from "../../UI/MyInputGroup";

const DataGroup = ({ dataForm, setDataForm }) => {
  const setMax = (e) => {
    let max = Number(e.target.value);
    if (max > 12 || max < 1) return;

    let a = NewTimeArr(dataForm.timeTable, max, dataForm.lessonDuration);
    setDataForm({ ...dataForm, maxLessons: max, timeTable: a });
  };
  return (
    <div>
      {" "}
      <div className="d-flex">
        <MyInputGroup
          cl={{ width: "50%", marginRight: "2rem" }}
          size="sm"
          required
          label="duration (min)"
          type="number"
          placeholder="40"
          value={dataForm.lessonDuration}
          onChange={(e) => {
            let a = durationShift(
              dataForm.time,
              e.target.value - dataForm.lessonDuration
            );
            setDataForm({ ...dataForm, duration: e.target.value, time: a });
          }}
        />
        <MyInputGroup
          cl={{ width: "50%", marginRight: "1rem" }}
          size="sm"
          required
          label="max lessons per day"
          type="number"
          placeholder="1"
          value={dataForm.maxLessons}
          onChange={setMax}
        />{" "}
      </div>
    </div>
  );
};

export default DataGroup;
