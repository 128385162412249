import React from "react";
import { getUserWeek } from "../../../utils/date";

import WeekDayTitle from "./WeekDayTitle";

const WeeksTitle = ({ dataSch }) => {
  const userWeek = getUserWeek(dataSch.sch);

  return (
    <div className="mt-4 position-relative">
      {userWeek.map((item) => (
        <WeekDayTitle
          key={item}
          dataSch={{ ...dataSch, day: item, lessons: dataSch.lessons[item] }}
        />
      ))}
    </div>
    // </div>
  );
};

export default WeeksTitle;
