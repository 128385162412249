export const getMonthNow = () => {
  let numM =
    (new Date().getMonth() < 9 ? "0" : "") + (new Date().getMonth() + 1);

  return new Date().getFullYear() + "-" + numM;
};

export const getDayNow = (sch) => {
  let st = new Date();
  st.setHours(12, 0, 0, 0);
  // ed.setDate(ed.getDate() - 7);
  // const start = sch.start;
  // let dif = 0;
  // //skiped
  // let [i, k] = [0, 0];
  // while (i < 7) {
  //   if (sch.days[st.getDay()]) i = 8;
  //   else {
  //     st.setDate(st.getDate() + 1);
  //     i++;
  //     k++;
  //   }
  // }
  // dif = k === 0 ? dif : dif + k - (k + dif >= 7 ? 7 : 0);
  // if (start < st.getDay()) st.setDate(st.getDate() - dif);
  // else if (st.getDate() !== 1) st.setDate(st.getDate() - dif);

  return [st];
};

export const defineCurrentWeek = (weeksNumber, startDay, day = "") => {
  let dt = new Date(weeksNumber.date);
  let dt_days = dt.getDay() - startDay;
  dt_days = dt_days + (dt_days >= 0 ? 0 : 7);
  dt.setDate(dt.getDate() - dt_days);

  let defineDay = day ? new Date(day) : new Date();
  let defineDay_days = defineDay.getDay() - startDay;
  defineDay_days = defineDay_days + (defineDay_days >= 0 ? 0 : 7);
  defineDay.setDate(defineDay.getDate() - defineDay_days);

  let weeksFromStart = Math.ceil((defineDay - dt) / 60 / 60 / 24 / 1000);
  weeksFromStart = Math.trunc(weeksFromStart / 7);
  if (weeksFromStart === 0) return { ...weeksNumber };
  let per = Math.trunc(
    (weeksFromStart + weeksNumber.current) / weeksNumber.weeks
  );
  let rem = Math.abs(
    weeksFromStart + weeksNumber.current - per * weeksNumber.weeks
  );
  return {
    weeks: weeksNumber.weeks,
    current: rem === 0 ? weeksNumber.weeks : rem,
    date: defineDay.getTime(),
  };
};
export const getWeekNow = (sch, dt = "", weekNum = "") => {
  let st = dt ? new Date(dt) : new Date();
  st.setHours(12, 0, 0, 0);

  const start = sch.startDay;
  let dif = st.getDay() - start;
  //skiped
  let [i, k] = [0, 0];
  while (i < 7) {
    if (sch.workDays[st.getDay()]) i = 8;
    else {
      st.setDate(st.getDate() + 1);
      i++;
      k++;
    }
  }
  dif = k === 0 ? dif : dif + k - (k + dif >= 7 ? 7 : 0);
  if (start < st.getDay()) st.setDate(st.getDate() - dif);
  else if (st.getDate() !== 1) st.setDate(st.getDate() - dif);
  let ed = new Date(st);
  ed.setDate(ed.getDate() + 7);

  return [st, ed, weekNum ? weekNum : weekByDate(st)];
};

export const defineTimeNow = (val, schedule) => {
  if (val === "month") return getMonthNow();
  if (val === "week") return getWeekNow(schedule);
  if (val === "day") {
    const today = new Date();
    today.setHours(12, 0, 0, 0);
    return today;
  }
};
export const defineTimeSelect = (interval, val, schedule) => {
  if (interval === "month") return val;
  if (interval === "day") return new Date(val);
  if (interval === "week") {
    let dt = dateByWeek(val);
    return getWeekNow(schedule, dt, val);
  }
};
export const monthStrToArr = (month) => {
  let regExTime = /([0-9][0-9][0-9][0-9])-([0-9][0-9])/;
  let res = regExTime.exec(month);
  return [res[1], res[2]];
};

export const getMonthSE = (sch, month) => {
  let [y, m] = monthStrToArr(month);
  let st = new Date(Number(y), Number(m) - 1, 1, 12, 0, 0, 0);
  let ed = new Date(Number(y), Number(m), 1, 12, 0, 0, 0);
  ed.setDate(ed.getDate() - 1);
  //   const ed = new Date("2022.10.31");
  const start = sch.startDay;
  let dif = st.getDay() - start;

  //skiped
  let [i, k] = [0, 0];
  while (i < 7) {
    if (sch.workDays[st.getDay()]) i = 8;
    else {
      st.setDate(st.getDate() + 1);
      i++;
      k++;
    }
  }
  dif = k === 0 ? dif : dif + k - (k + dif >= 7 ? 7 : 0);
  if (start < st.getDay()) st.setDate(st.getDate() - dif);
  else if (st.getDate() !== 1) st.setDate(st.getDate() - dif);

  return [st, ed];
};

export const getRange = (schedule, selectedTime, interval) => {
  if (interval === "month") return getMonthSE(schedule, selectedTime);
  if (interval === "week") return [selectedTime[0], selectedTime[1]];
  if (interval === "day") return [selectedTime, selectedTime];
};

export const calendArr = (data, month) => {
  const start = data.sch.startDay;
  const [st, ed] = getMonthSE(data.sch, month);
  let currentWeek = defineCurrentWeek(
    data.sch.weeksNumber,
    data.sch.startDay,
    st
  ).current;
  const oneDayinMs = 1000 * 60 * 60 * 24;
  // Calculating the time difference between two dates
  const diffInTime = ed.getTime() - st.getTime();
  const diffInDays = 1 + Math.round(diffInTime / oneDayinMs);
  const weeks = Math.trunc(diffInDays / 7) + (diffInDays % 7 ? 1 : 0);

  // const cArr = Array.from({ length: weeks }, (_, i) => i);
  let userWeekL = [];

  for (let i = 0; i < 7; i++) {
    userWeekL.push(
      data.sch.workDays[i + start - (i + start > 6 ? 7 : 0)]
        ? data.lessons[i + start - (i + start > 6 ? 7 : 0)]
        : false
    );
  }
  console.log("userWeekL");
  console.log(userWeekL);
  let emptArr = userWeekL.map((el) => (el ? [] : false));
  let wArr = [];
  let cArr = [];
  // let emptArr = [[],[],[],[],[],[],[]];
  for (let i = 0; i < weeks; i++) {
    cArr.push(
      // eslint-disable-next-line no-loop-func
      userWeekL.map((el, j) =>
        el[currentWeek - 1] ? el[currentWeek - 1] : emptArr[j]
      )
    );
    // cArr.push(userWeekL.map((el) => (el ? el[currentWeek - 1] : el)));
    wArr.push(currentWeek);
    currentWeek =
      currentWeek === data.sch.weeksNumber.weeks ? 1 : currentWeek + 1;
  }
  return [cArr, st, wArr];
};

export const newDateFormat = (dt = new Date()) => {
  if (typeof dt == "string" && dt[10] === "T") dt = dt.slice(0, 10);
  let nd = new Date(dt);
  nd.setHours(0, 0, 0, 0);
  return nd;
};

export const DateFormatYYYYMMDD = (dt) => {
  if (!(dt instanceof Date)) return dt;
  return dt.toISOString().slice(0, 10);
};
export const datesDiff = (d1, d2) => {
  return newDateFormat(d2) - newDateFormat(d1);
};
//2018-W26
export const dateByWeek = (weekStr) => {
  let [y, w] = weekStr.split("-W");
  const dayFromYearStart = w * 7 - 5;
  var day = new Date(y, 0, 1);
  day.setDate(day.getDate() + dayFromYearStart);
  return day;
};
//2018-W26
export const weekByDate = (date) => {
  let y = date.getFullYear();
  // let [y, w] = weekStr.split("-W");
  // const dayFromYearStart = w * 7 - 5;
  var start = new Date(y, 0, 1);
  let dayFromYearStart = Math.ceil((date - start) / 60 / 60 / 24 / 1000); // day.setDate(day.getDate() + dayFromYearStart);
  let week = Math.ceil(dayFromYearStart / 7);
  return y + "-W" + (week < 10 ? "0" : "") + week;
};

export const datePlusDaysStr = (date, days) => {
  let dt = new Date(date);
  dt.setDate(dt.getDate() + days);
  return dt.toISOString().slice(0, 10);
};
export const datePlusDays = (date, days) => {
  let dt = new Date(date);
  dt.setDate(dt.getDate() + days);
  return dt;
};

export const datePlusMonth = (date, months) => {
  let [y, m] = monthStrToArr(date);
  m = Number(m);
  y = Number(y) + Math.trunc(months / 12);
  m = m + (months - 12 * Math.trunc(months / 12));

  if (m < 1) {
    m = 12 + m;
    y--;
  } else if (m > 12) {
    m = m - 12;
    y++;
  }
  m = m < 1 ? 12 + m : m;
  m = m > 12 ? m - 12 : m;
  return y + "-" + (m < 10 ? "0" : "") + m;
};

export const weekDayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const weekDayShortNames = [
  "Sun",
  "Mon",
  "Tues",
  "Wed",
  "Thurs",
  "Fri",
  "Sat",
];
//user week ordered by user's week start day
export const getUserWeek = (schedule) => {
  return [0, 0, 0, 0, 0, 0, 0]
    .map((_, i) => i + schedule.startDay - (i + schedule.startDay > 6 ? 7 : 0))
    .filter((el) => schedule.workDays[el]);
};
//user week data ordered by user's week start day
export const getUserWeekDate = (schedule, startDate) => {
  let userweek = getUserWeek(schedule);
  let result = [];
  let sd = new Date(startDate);

  for (let i = 0; i < 7; i++) {
    if (userweek.includes(sd.getDay())) result.push(new Date(sd));
    sd.setDate(sd.getDate() + 1);
  }
  let curweek = defineCurrentWeek(
    schedule.weeksNumber,
    schedule.startDay,
    startDate
  );
  return [result, curweek.current];
};
export const nextDate = (interval, time, direction) => {
  if (interval === "month") {
    //2022-12
    return datePlusMonth(time, direction);
  }
  if (interval === "week") {
    //[startDay,endDay]
    let start = datePlusDays(time[0], 7 * direction);
    return weekByDate(start);
    // let end = datePlusDays(time[1], 7 * direction);
    // return datePlusDays([start, end]);
  }
  if (interval === "day") {
    //day
    return datePlusDays(time, direction);
  }
};
