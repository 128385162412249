import React from "react";
import { Form } from "react-bootstrap";
import OneToggleButton from "../../UI/Buttons/OneToggleButton";
import cl from "./editSchedule.module.scss";
const WeekNumbers = ({ dataForm, setDataForm }) => {
  const selectArr = Array.from(
    { length: dataForm.weeksNumber.weeks },
    (_, i) => i + 1
  );
  return (
    dataForm && (
      <div className={cl.weeknumbers_div}>
        {" "}
        <div className="d-flex justify-content-between">
          <div className="me-1"> Weeks count</div>

          <div className="d-flex justify-content-start">
            {[1, 2, 3, 4].map((el, i) => (
              <OneToggleButton
                key={"weeksNumber" + i}
                type="radio"
                name="weeksNumber"
                id={"weeksNumber" + i}
                outline={true}
                checked={dataForm.weeksNumber.weeks === i + 1}
                className={
                  dataForm.weeksNumber.weeks === i + 1
                    ? cl.badgeWeekOn
                    : cl.badgeWeekOff
                }
                onChange={(e) => {
                  setDataForm({
                    ...dataForm,
                    weeksNumber: { ...dataForm.weeksNumber, weeks: el },
                  });
                }}
                title={el}
              />
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>current week</div>
          <Form.Select
            bsPrefix=""
            aria-label="current week"
            className={cl.currentWeek}
            defaultValue={dataForm.weeksNumber.current}
            onChange={(e) => {
              setDataForm({
                ...dataForm,
                weeksNumber: {
                  ...dataForm.weeksNumber,
                  date: new Date().getTime(),
                  current: Number(e.target.value),
                },
              });
            }}>
            {selectArr.map((el) => (
              <option key={el} value={el}>
                {el}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
    )
  );
};

export default WeekNumbers;
