import React from "react";
import { Form } from "react-bootstrap";
import { weekDayShortNames } from "../../../utils/date";
import OneToggleButton from "../../UI/Buttons/OneToggleButton";
import cl from "./editSchedule.module.scss";

const DaysGroupRow = ({ dataForm, setDataForm }) => {
  return (
    <div className="w-100">
      <div className="d-flex">
        <Form.Label className="mt-1 me-3">Study days</Form.Label>
        <div className="d-flex flex-row">
          {weekDayShortNames.map((el, i) => (
            <OneToggleButton
              key={i}
              type="checkbox"
              id={i}
              outline={true}
              className={
                dataForm.workDays[i] ? cl.weekDaybadgeOn : cl.weekDaybadgeOff
              }
              checked={dataForm.workDays[i]}
              onChange={(e) => {
                let a = [...dataForm.workDays];
                a[i] = e.target.checked;
                setDataForm({
                  ...dataForm,
                  workDays: a,
                });
              }}
              title={el}
            />
          ))}
        </div>
      </div>
      <div className="d-flex">
        <Form.Label className=" me-4">Week start</Form.Label>
        <div className={cl.weekStartRow}>
          {weekDayShortNames.map((el, i) => (
            <OneToggleButton
              key={"radiostart" + i}
              type="radio"
              name="radiostart"
              id={"radiostart" + i}
              outline={true}
              className={dataForm.startDay === i ? cl.badgeOn : cl.badgeOff}
              style={{
                height: "1rem",
                width: "1rem",
                marginLeft: "1rem",
              }}
              checked={dataForm.startDay === i}
              onChange={(e) => {
                setDataForm({
                  ...dataForm,
                  startDay: i,
                });
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DaysGroupRow;
