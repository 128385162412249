import React, { useState } from "react";
import cl from "./editSchedule.module.scss";
import EditSchedule from "./EditSchedule";
import OneButton from "../../UI/Buttons/OneButton";

const NewScheduleBtn = ({ refresh }) => {
  const [creationMode, setCreationMode] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [schedule, setSchedule] = useState({
    name: "",
    lessonDuration: 40,
    timeTable: ["08:00"],
    workDays: [true, true, true, true, true, true, true],
    startDay: 0,
    weeksNumber: { weeks: 1, current: 1, date: new Date().getTime() },
    maxLessons: 1,
  });
  const newS = () => {
    setCreationMode(!creationMode);
  };
  const add = (listData) => {
    setCreationMode(false);

    refresh(listData);
  };

  return (
    <div>
      <OneButton
        title="+ CREATE A NEW SCHEDULE"
        outline={true}
        onClick={newS}
        className={cl.createNewBtn}
      />
      {creationMode && (
        <div>
          <EditSchedule ok={add} esc={setCreationMode} data={schedule} />
        </div>
      )}
    </div>
  );
};

export default NewScheduleBtn;
