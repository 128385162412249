import React from "react";
import { getBreak, timeShift } from "../../../utils/timeStr";
import TimeInputGroup from "./TimeInputGroup";

const TimeGroup = ({ dataForm, setDataForm }) => {
  const max = Array.from({ length: Number(dataForm.maxLessons) }, (_, i) => i);

  const onchange = (e, i, isTime) => {
    let a = timeShift(isTime, [
      dataForm.timeTable,
      i,
      e.target.value,
      dataForm.lessonDuration,
    ]);

    setDataForm({
      ...dataForm,
      timeTable: a,
    });
  };

  return (
    <div className="ms-2 w-100 border-start">
      {" "}
      <p className="mb-2">Timetable</p>
      {max.map((_, i) => (
        <TimeInputGroup
          key={"tt" + i + dataForm.timeTable[i]}
          time={dataForm.timeTable[i]}
          i={i}
          br={getBreak(dataForm, i)}
          last={max !== i + 1}
          onchange={onchange}
        />
      ))}
    </div>
  );
};

export default TimeGroup;
