import React from "react";
import { getUserWeekDate } from "../../../utils/date";
import OneDay from "./OneDay";
import clweek from "./diaryweek.module.css";

const DiaryWeek = ({ dataSch, dateRange, dataNotes }) => {
  // const [weekStyle, setWeekStyle] = useState("column");
  const [userWeekData, curweek] = getUserWeekDate(dataSch.sch, dateRange[0]);

  return (
    <div className="d-flex w-100 mt-4 justify-content-center">
      {dateRange && (
        <div className={clweek["week" + dataSch.direction]}>
          {userWeekData.map((el, i) => (
            <OneDay
              key={i}
              dataSch={dataSch}
              dataNotes={dataNotes}
              date={el}
              weekstyle={dataSch.direction}
              week={curweek}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default DiaryWeek;
