import React from "react";
import { Button } from "react-bootstrap";

const OneButton = ({ title, outline = false, ...props }) => {
  return (
    <Button variant={outline ? "outline-success" : "success"} {...props}>
      {title}
    </Button>
  );
};

export default OneButton;
