import { datesDiff } from "./date";

// export const newArrayByDays = (arr) => {
//   const newArr = [[], [], [], [], [], [], []];
//   if (!arr) return newArr;
//   arr.sort((a, b) => a.ordNum - b.ordNum);
//   arr.forEach((element) => {
//     newArr[element.weekDay].push(element);
//   });

//   return newArr;
// };

export const shiftSubjNum = (list) => {
  const updArr = [];
  list.forEach((element, i) => {
    if (!element) return;
    if (element.ordNum === i) return;
    element.ordNum = i;
    updArr.push(element);
  });
  return updArr;
};

export const dragDrop = (from, to, subjects) => {
  let newarr = [...subjects];
  const list = [...subjects[from.weekDay]];
  const weekfrom = from.weekDay;
  const weekto = to.weekDay;
  let upd = [];
  if (from.weekDay !== to.weekDay) {
    const listTo = [...subjects[to.weekDay]];
    from.weekDay = to.weekDay;
    list.splice(from.ordNum, 1, null);
    from.ordNum = to.ordNum + 1;

    // listTo.splice(to.ordNum + 1, 0, from); //(from.num >= to.num ? 0 : 1)
    if (to.hasOwnProperty("id")) listTo.splice(to.ordNum + 1, 0, from);
    else listTo.splice(to.ordNum, 1, from);

    if (from.ordNum === to.ordNum + 1) upd.push(from);
    // upd = upd.concat(shiftSubjNum(list));
    upd = upd.concat(shiftSubjNum(listTo));

    // if (!listTo[listTo.length - 1]) listTo.splice(listTo.length - 1, 1);
    newarr[weekto] = listTo;
    if (!list[list.length - 1]) list.splice(list.length - 1, 1);
    newarr[weekfrom] = list;
    // upd = upd.concat([from]);
  } else {
    if (to.hasOwnProperty("id")) {
      list.splice(to.ordNum + (from.ordNum > to.ordNum ? 0 : 1), 0, from);
      list.splice(from.ordNum + (from.ordNum > to.ordNum ? 1 : 0), 1);
    } else {
      //not to the end
      if (list.length !== to.ordNum) {
        list.splice(to.ordNum, 1, from);
        list.splice(from.ordNum, 1, null);
      } else {
        list.splice(to.ordNum, 0, from);
        list.splice(from.ordNum, 1, null);
      }

      //   list.splice(to.ordNum + (from.ordNum > to.ordNum ? 0 : 1), 0, from);
      // list.splice(from.ordNum + (from.ordNum > to.ordNum ? 1 : 0), 1);
    }
    if (!list[list.length - 1]) list.splice(list.length - 1, 1);
    newarr[weekfrom] = list;
    upd = upd.concat(shiftSubjNum(list));
  }

  return [upd, newarr];
};

export const subjList = (list) => {
  let res = [];
  list.forEach((item, i) => {
    item.forEach((el, i) => {
      if (!res.includes(el.name)) res.push(el.name);
    });
  });
  return res;
};

export const nameById = (arr, id) => {
  const el = arr.find((item) => item.id.toString() === id.toString());
  if (!el) return undefined;
  return el.name;
};
// export const SetById = (arr, id) => {
//   if (!id) return {};
//   const el = arr.find((item) => item.id.toString() === id.toString());
//   if (!el) return undefined;
//   let col = !el.color ? "#f8f1f8" : el.color;
//   return { name: el.name, color: col };
// };

export const DayNoteById = (arr, id) => {
  if (!arr) return undefined;

  const el = arr.find((item) => item.lessonid.toString() === id.toString());
  if (!el) return undefined;
  return el;
};
export const DayNote = (arr, day) => {
  if (!arr) return [];
  const el = arr.filter((item) => {
    return datesDiff(day, Number(item.date)) === 0;
  });
  if (!el) return [];
  return el;
};

export const noteById = (arr = [], id, date) => {
  let note;
  try {
    note = arr.find(
      (el) =>
        el.lessonid.toString() === id.toString() &&
        datesDiff(date, Number(el.date)) === 0
    );
  } catch (error) {
    note = "";
  }
  return note ? note : "";
};

export const delLessPlanBySubject = (lessons, subjectid) => {
  return lessons.map((el) => {
    let newel = [];
    let last = el.length - 1;
    el.forEach((item, i) => {
      if (item.subjectid !== subjectid) newel.push(item);
      else if (i !== last) newel.push(null);
    });

    return newel;
  });
};

export const titleArrForDiary = (lessons) => {
  const arrTitle = [[]];
  if (!lessons || lessons.length === 0) return [];
  lessons.forEach((element, week) => {
    element.forEach((el, ordNum) => {
      let elset =
        el !== null
          ? { ...el, weekT: week + 1 }
          : { subjectName: "", color: "#E9ECEF", weekT: week + 1 };
      if (ordNum + 1 > arrTitle.length) arrTitle.push([]);
      let last = arrTitle[ordNum].length - 1;
      if (last < 0) arrTitle[ordNum].push(elset);
      else if (arrTitle[ordNum][last].subjectName !== el.subjectName)
        arrTitle[ordNum].push(elset);
      else
        arrTitle[ordNum][last].weekT =
          arrTitle[ordNum][last].weekT + "," + (week + 1);
    });
  });
  console.log(arrTitle);
  return arrTitle;
};
