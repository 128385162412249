import React, { useState } from "react";
import cl from "../diary.module.scss";
import clmonth from "./diarymonth.module.scss";

import EditBox from "../../Schedule/EditBox";
import { BaseAPI } from "../../../API/BaseApi";

const LessonBoxC = ({ item, i, note, day, update, weekstyle }) => {
  const [editMode, setEditMode] = useState(false);
  const [newNote, setNewNote] = useState(note ? note : { note: "" });

  const editSwitch = (val = !editMode) => {
    setEditMode(val);
  };
  const editOf = () => {
    setEditMode(false);
  };
  const del = async (nt) => {
    await BaseAPI.deleteNote(nt.id);
    await update();
  };
  const updatePage = async () => {
    if (!newNote) return;
    if (!!newNote.id) await BaseAPI.updateNote(newNote.id, newNote.note);
    else await BaseAPI.createNote(newNote, item.scheduleid, item.id, day);
    setEditMode(!editMode);
    await update();
  };
  const backcolor = {
    backgroundColor: item !== null ? item.color : "#E9ECEF",
    color: "black",
  };

  return (
    <>
      {editMode && (
        <EditBox
          value={newNote.note}
          setValue={(v) => setNewNote({ ...newNote, note: v })}
          ok={updatePage}
          esc={editOf}
          title={day.toISOString().slice(0, 10) + ": " + item.subjectName}
          del={() => {
            editSwitch();
            del(newNote);
          }}
        />
      )}{" "}
      <div
        onClick={editSwitch}
        className={clmonth["lessonbox" + weekstyle]}
        style={note ? backcolor : {}}>
        {weekstyle !== "row" && (
          <span className={clmonth.numberstyle}>{i + 1}</span>
        )}
        {weekstyle === "row" ? (
          <>
            <div style={backcolor} className={clmonth.subjectrow}>
              <span>{i + 1}</span> {item === null ? "" : item.subjectName}
            </div>
            <div className={cl.lessBoxCText}>{note ? note.note : ""}</div>
          </>
        ) : (
          <div
            className={note ? cl.lessBoxCText : cl.lessBoxCPlaceholder}
            style={!note ? backcolor : {}}>
            {note ? note.note : item.subjectName}
          </div>
        )}
      </div>
    </>
  );
};

export default LessonBoxC;
