/* eslint-disable no-unused-vars */
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import UserProfile from "./UserProfile";
import MySpinner from "../UI/MySpinner";
// import { usePopup } from "../../hooks/usePopup";
import { useLoading } from "../../hooks/useLoading";
import { BaseAPI } from "../../API/BaseApi";

const Profile = () => {
  const [userData, setUserData] = useState(null);
  // const setPopup = usePopup();
  const [getUserData, isLoading, error] = useLoading(async () => {
    const userData = await BaseAPI.getUser();

    if (userData) setUserData(userData);
  });

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUser = async (data) => {
    let result = await BaseAPI.updateUser(data);
    if (error) {
      // setPopup.error(error);
      return;
    }

    setUserData({ ...userData, ...data });
  };
  return (
    <div className="mt-5">
      {isLoading || !userData ? (
        <MySpinner />
      ) : (
        <UserProfile
          userData={userData}
          btnName="Save changes"
          onClick={updateUser}
        />
      )}
    </div>
  );
};

export default Profile;
