// const timeStrToArr = (timeStr) => {
//   let regExTime = /([0-9]?[0-9]):([0-9][0-9])/;
//   return regExTime.exec(timeStr);
// };
const timeStrInMin = (timeStr) => {
  let regExTime = /([0-9]?[0-9]):([0-9][0-9])/;
  let result = regExTime.exec(timeStr);
  let timeInmin = Number(result[1]) * 60 + Number(result[2]);

  return timeInmin;
};

export const checkTime = (timeArr, i, newVal, duration) => {
  //no earlier then previous

  if (i > 0 && timeStrInMin(timeArr[i - 1] + duration) <= timeStrInMin(newVal))
    return false;

  return true;
};

const timeMinToStr = (timeInmin) => {
  let min = Number(timeInmin) % 60;
  let hours = Math.trunc(Number(timeInmin) / 60);
  hours = hours - 24 * Math.trunc(hours / 24);
  hours = hours + (hours < 0 ? 24 : 0);
  return (hours < 10 ? "0" : "") + hours + ":" + (min < 10 ? "0" : "") + min;
};

const timeStrShift = (timeStr, val) => {
  let timeInmin = timeStrInMin(timeStr);
  return timeMinToStr(timeInmin + val);
};

export const NewTimeArr = (timeArr, max, duration) => {
  if (!Array.isArray(timeArr)) return timeArr;
  let a = [...timeArr];
  if (a.length < max) {
    let timeInmin = timeStrInMin(a[0]);
    for (let i = a.length; i < max; i++) {
      a.push(timeMinToStr(timeInmin + i * (duration + 10)));
    }
  }
  return a;
};
export const timeShiftLess = (timeArr, i, newVal) => {
  let a = [...timeArr];
  let newValMin = timeStrInMin(newVal);
  let oldValMin = timeStrInMin(a[i]);
  let shift = newValMin - oldValMin;
  a[i] = newVal;
  for (let j = i + 1; j < a.length; j++) {
    a[j] = timeStrShift(a[j], shift);
  }

  if (shift > 0) return a;
  for (let j = 0; j < i; j++) {
    a[j] = timeStrShift(a[j], shift);
  }
  return a;
};
export const durationShift = (timeArr, shift) => {
  let a = [...timeArr];

  for (let j = 1; j < a.length; j++) {
    a[j] = timeStrShift(a[j], shift);
  }
  return a;
};
export const timeShiftBr = (timeArr, i, br, duration) => {
  let a = [...timeArr];
  let oldValMin = timeStrInMin(timeArr[i]);
  let oldValMin2 = timeStrInMin(timeArr[i + 1]);
  let shift = br - (oldValMin2 - oldValMin - duration);
  for (let j = i + 1; j < a.length; j++) {
    a[j] = timeStrShift(a[j], shift);
  }
  return a;
};
export const timeShift = (isTime, attribute) => {
  return isTime ? timeShiftLess(...attribute) : timeShiftBr(...attribute);
};
export const getBreak = (dataarr, i) => {
  if (dataarr.maxLessons === i + 1) return 10;
  if (!dataarr.timeTable[i + 1]) return 10;
  return (
    timeStrInMin(dataarr.timeTable[i + 1]) -
    timeStrInMin(dataarr.timeTable[i]) -
    dataarr.lessonDuration
  );
};
export const getEnd = (time, duration) => {
  return timeMinToStr(timeStrInMin(time) + duration);
};
