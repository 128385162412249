import React from "react";
import cl from "./diarymonth.module.scss";
import Calendar from "./Calendar";
import WeeksTitle from "./WeeksTitle";

const DiaryMonth = ({ dataSch, dateRange, dataNotes }) => {
  return (
    <>
      {dateRange && (
        <div className={cl["month" + dataSch.direction]}>
          {/* <div className="mt-4 d-flex justify-content-between position-relative"> */}
          {dataSch.direction === "column" && <WeeksTitle dataSch={dataSch} />}
          {/* </div> */}
          <Calendar
            dataSch={dataSch}
            dataNotes={dataNotes}
            dateRange={dateRange}
          />
        </div>
      )}
    </>
  );
};

export default DiaryMonth;
