import React, { useState } from "react";
import { BaseAPI } from "../../API/BaseApi";
import EditBox from "../Schedule/EditBox";

import SubjCardBase from "./SubjCardBase";

const SubjectsList = ({ subj, drag, reload }) => {
  const [editMode, setEditMode] = useState("");
  const [newSub, setNewSub] = useState("");
  const [color, setColor] = useState(subj.color);
  const update = async () => {
    if (!editMode.name) return;
    await BaseAPI.updateSubject(editMode.id, { name: newSub, color: color });
    setEditMode("");
    reload(color, editMode.id);
  };

  const del = async () => {
    if (!window.confirm("Delete the subject?")) return;
    await BaseAPI.deleteSubject(editMode);
    reload(editMode.id);
  };
  const editOn = (el) => {
    setEditMode(el);
  };

  return (
    <div className="position-relative " style={{ minWidth: "8%" }}>
      {editMode && (
        <EditBox
          value={newSub}
          setValue={setNewSub}
          ok={update}
          esc={() => {
            setEditMode("");
          }}
          del={() => {
            setEditMode("");
            del(editMode);
          }}
          col={{
            setColor: setColor,
            color: editMode.color ? editMode.color : "#f8f1f8",
          }}
        />
      )}
      <div className="position-sticky top-5 ">
        {subj.map((item) => (
          <SubjCardBase
            key={item.id}
            drag={drag}
            item={item}
            setEditMode={() => editOn(item)}
            setNewSub={setNewSub}
          />
        ))}
      </div>
    </div>
  );
};

export default SubjectsList;
