// import { newDateFormat } from "../utils/date";

const dataBase = {
  list: [
    {
      id: 1663348413886,
      name: "7-b",
      note: "",
      duration: 40,
      weeks: 1,
      days: [1, 1, 1, 1, 1, 1, 1],
      time: [
        "08:00",
        "08:50",
        "09:40",
        "10:30",
        "11:20",
        "12:10",
        "13:00",
        "13:50",
      ],
      max: 7,
      start: 0,
    },
  ],
  content: [
    {
      id: 1663348422933,
      listid: "1663348413886",
      subid: 1663348422933,
      num: 0,
      day: 1,
    },
    {
      id: 1663348427289,
      listid: "1663348413886",
      subid: 1663348427289,
      num: 1,
      day: 1,
    },
    {
      id: 1663348431334,
      listid: "1663348413886",
      subid: 1663348431334,
      num: 2,
      day: 1,
    },
    {
      id: 1663348436184,
      listid: "1663348413886",
      subid: 1663348431334,
      num: 3,
      day: 1,
    },
    {
      id: 1663348440524,
      listid: "1663348413886",
      subid: 1663348440524,
      num: 4,
      day: 1,
    },
    {
      id: 1663348444157,
      listid: "1663348413886",
      subid: 1663348444157,
      num: 5,
      day: 1,
    },
    {
      id: 1663348456399,
      listid: "1663348413886",
      subid: 1663348440524,
      num: 0,
      day: 2,
    },
    {
      id: 1663348468105,
      listid: "1663348413886",
      subid: 1663348468105,
      num: 1,
      day: 2,
    },
    {
      id: 1663348471819,
      listid: "1663348413886",
      subid: 1663348471819,
      num: 2,
      day: 2,
    },
    {
      id: 1663348476159,
      listid: "1663348413886",
      subid: 1663348422933,
      num: 3,
      day: 2,
    },
    {
      id: 1663348481409,
      listid: "1663348413886",
      subid: 1663348481409,
      num: 4,
      day: 2,
    },
    {
      id: 1663348484605,
      listid: "1663348413886",
      subid: 1663348431334,
      num: 5,
      day: 2,
    },
    {
      id: 1663348488904,
      listid: "1663348413886",
      subid: 1663348488904,
      num: 0,
      day: 3,
    },
    {
      id: 1663348492284,
      listid: "1663348413886",
      subid: 1663348492284,
      num: 1,
      day: 3,
    },
    {
      id: 1663348496296,
      listid: "1663348413886",
      subid: 1663348468105,
      num: 2,
      day: 3,
    },
    {
      id: 1663348499850,
      listid: "1663348413886",
      subid: 1663348431334,
      num: 3,
      day: 3,
    },
    {
      id: 1663348502191,
      listid: "1663348413886",
      subid: 1663348471819,
      num: 4,
      day: 3,
    },
    {
      id: 1663348506327,
      listid: "1663348413886",
      subid: 1663348422933,
      num: 5,
      day: 3,
    },
    {
      id: 1663348513230,
      listid: "1663348413886",
      subid: 1663348481409,
      num: 6,
      day: 3,
    },
    {
      id: 1663348516665,
      listid: "1663348413886",
      subid: 1663348422933,
      num: 0,
      day: 4,
    },
    {
      id: 1663348523653,
      listid: "1663348413886",
      subid: 1663348523652,
      num: 1,
      day: 4,
    },
    {
      id: 1663348526698,
      listid: "1663348413886",
      subid: 1663348468105,
      num: 2,
      day: 4,
    },
    {
      id: 1663348533062,
      listid: "1663348413886",
      subid: 1663348533062,
      num: 3,
      day: 4,
    },
    {
      id: 1663348537050,
      listid: "1663348413886",
      subid: 1663348481409,
      num: 4,
      day: 4,
    },
    {
      id: 1663348540623,
      listid: "1663348413886",
      subid: 1663348431334,
      num: 5,
      day: 4,
    },
    {
      id: 1663348543887,
      listid: "1663348413886",
      subid: 1663348488904,
      num: 6,
      day: 4,
    },
    {
      id: 1663348547067,
      listid: "1663348413886",
      subid: 1663348422933,
      num: 0,
      day: 5,
    },
    {
      id: 1663348551326,
      listid: "1663348413886",
      subid: 1663348551326,
      num: 1,
      day: 5,
    },
    {
      id: 1663348556280,
      listid: "1663348413886",
      subid: 1663348492284,
      num: 2,
      day: 5,
    },
    {
      id: 1663348561100,
      listid: "1663348413886",
      subid: 1663348533062,
      num: 3,
      day: 5,
    },
    {
      id: 1663348564496,
      listid: "1663348413886",
      subid: 1663348427289,
      num: 4,
      day: 5,
    },
    {
      id: 1663348573394,
      listid: "1663348413886",
      subid: 1663348468105,
      num: 5,
      day: 5,
    },
    {
      id: 1663348576586,
      listid: "1663348413886",
      subid: 1663348576586,
      num: 6,
      day: 5,
    },
  ],
  subjects: [
    { id: 1663348422933, listid: "1663348413886", name: "JP", color: "" },
    { id: 1663348427289, listid: "1663348413886", name: "JN", color: "" },
    { id: 1663348431334, listid: "1663348413886", name: "Mat", color: "" },
    { id: 1663348440524, listid: "1663348413886", name: "G", color: "" },
    { id: 1663348444157, listid: "1663348413886", name: "Muz", color: "" },
    { id: 1663348468105, listid: "1663348413886", name: "WF", color: "" },
    { id: 1663348471819, listid: "1663348413886", name: "H", color: "" },
    { id: 1663348481409, listid: "1663348413886", name: "JA", color: "" },
    { id: 1663348488904, listid: "1663348413886", name: "F", color: "" },
    { id: 1663348492284, listid: "1663348413886", name: "Ch", color: "" },
    { id: 1663348523652, listid: "1663348413886", name: "ZZW", color: "" },
    { id: 1663348533062, listid: "1663348413886", name: "Bio", color: "" },
    { id: 1663348551326, listid: "1663348413886", name: "Pl", color: "" },
    { id: 1663348576586, listid: "1663348413886", name: "Inf", color: "" },
  ],
  time: [
    {
      id: 0,
      listid: "1663348413886",
      num: 0,
      start: "8-00",
      finish: "8-40",
    },
  ],
  users: [
    {
      id: 1,
      name: "test user",
      email: "test@test.test",
      password: 1,
      sessions: [],
      imgu: "https://firebasestorage.googleapis.com/v0/b/words-d2019.appspot.com/o/avatars%2Fav1.png?alt=media&token=d83bc75a-2744-49c2-b961-93c631c4351f",
    },
  ],
  user: {
    id: "",
    name: "",
  },
  notes: [
    {
      id: 1,
      listid: "1663348413886",
      lessid: "1663348431334",
      date: new Date("2022-09-26"),
      note: "p.25",
    },
    {
      id: 0,
      listid: "1663348413886",
      lessid: "1663348468105",
      date: new Date("2022-10-11"),
      note: "p.13-15",
    },
  ],
};

const ServerAPI = {
  async addUserDefiner(key) {
    let user = await this.getUser();
    if (user === undefined) return key;
    return key + "&" + user.id;
  },
  newDateFormat(dt = new Date()) {
    if (typeof dt == "string" && dt[10] === "T") dt = dt.slice(0, 10);
    let nd = new Date(dt);
    nd.setHours(0, 0, 0, 0);
    return nd;
  },
  async toLS(key, value) {
    let keyN = key;
    // if (key !== "users") keyN = this.addUserDefiner(key);
    localStorage.setItem(keyN, JSON.stringify(value));
  },
  async fromLS(key) {
    let keyN = key;
    // if (key !== "users") keyN = this.addUserDefiner(key);
    if (!localStorage.getItem(keyN)) this.createDB(keyN);
    return JSON.parse(localStorage.getItem(keyN));
  },
  // async createList(listData, name, note = "") {
  //   let listC = await this.fromLS("List");
  //   let id = +new Date();
  //   listC.push({
  //     id: id,
  //     name: listData.name,
  //     note: note,
  //     duration: listData.duration,
  //     days: listData.days,
  //     time: listData.time,
  //     max: listData.max,
  //     start: listData.start,
  //   });
  //   await this.toLS("List", listC);
  //   return id;
  // },

  // async createItem(name, listid, num, day, subid = "") {
  //   let idS = subid ? subid : await this.getOrAddS(listid, name);
  //   let listC = await this.fromLS("Content");
  //   let idC = +new Date();
  //   listC.push({
  //     id: idC,
  //     listid: listid,
  //     subid: idS,
  //     num: num,
  //     day: day,
  //   });
  //   await this.toLS("Content", listC);
  //   return idC;
  // },
  // async createNote(note, listid, lessid, date) {
  //   let list = await this.fromLS("Notes");
  //   let num = list.findIndex(
  //     (item) =>
  //       item.lessid.toString() === lessid.toString() &&
  //       item.listid.toString() === listid.toString() &&
  //       new Date(item.date).getTime() === date.getTime()
  //   );
  //   let id = +new Date();
  //   if (num === -1) {
  //     list.push({
  //       id: id,
  //       listid: listid,
  //       lessid: lessid,
  //       date: date,
  //       note: note.note,
  //     });
  //   } else list[num] = { ...list[num], note: note.note };

  //   await this.toLS("Notes", list);
  //   return id;
  // },
  // async createUser(ud) {
  //   let login = ud.email;
  //   let passw = ud.password;
  //   let name = ud.name;
  //   let imgu = ud.imgu;

  //   let usersList = await this.fromLS("users");
  //   let user = usersList.find((item) => item.email.trim() === login.trim());

  //   if (user !== undefined)
  //     return { status: false, error: "user already exist" };
  //   let uId = Date.now();
  //   localStorage.setItem("expressionsList" + uId, JSON.stringify([]));
  //   localStorage.setItem("collectionsList" + uId, JSON.stringify([]));
  //   localStorage.setItem("extraList" + uId, JSON.stringify([]));
  //   let newUser = {
  //     id: uId,
  //     name: name,
  //     email: login,
  //     password: passw,
  //     sessions: [],
  //     imgu: imgu,
  //   };

  //   let token = Date.now();
  //   newUser.sessions.push(token);
  //   usersList.push(newUser);
  //   localStorage.setItem("users", JSON.stringify(usersList));
  //   localStorage.setItem("tokenschedule", JSON.stringify(token));
  //   return {
  //     status: true,
  //   };
  // },
  // async deleteItem(itemId) {
  //   let list = await this.fromLS("Content");
  //   let indbase = list.findIndex(
  //     (item) => item.id.toString() === itemId.toString()
  //   );
  //   if (indbase !== -1) list.splice(indbase, 1);
  //   await this.toLS("Content", list);
  // },
  async deleteListContent(id) {
    let list = await this.fromLS("Content");
    let indbase = list.filter(
      (item) => item.listid.toString() !== id.toString()
    );
    await this.toLS("Content", indbase);
  },
  async deleteListSubj(id) {
    let list = await this.fromLS("Subjects");
    let indbase = list.filter(
      (item) => item.listid.toString() !== id.toString()
    );
    await this.toLS("Subjects", indbase);
  },
  // async deleteList(id) {
  //   let list = await this.fromLS("List");
  //   let num = list.findIndex((item) => item.id.toString() === id.toString());
  //   this.deleteListContent(id);
  //   this.deleteListSubj(id);
  //   list.splice(num, 1);
  //   await this.toLS("List", list);
  // },
  // async deleteNote(id) {
  //   let list = await this.fromLS("Notes");
  //   let indbase = list.filter((item) => item.id.toString() !== id.toString());
  //   await this.toLS("Notes", indbase);
  // },
  // async deleteSubj(el) {
  //   let list = await this.fromLS("Subjects");
  //   let indbase = list.filter(
  //     (item) =>
  //       item.id.toString() !== el.id.toString() ||
  //       item.listid.toString() !== el.listid.toString()
  //   );
  //   await this.toLS("Subjects", indbase);

  //   list = await this.fromLS("Content");

  //   indbase = list.filter(
  //     (item) => item.subjectid.toString() !== el.id.toString()
  //   );
  //   if (indbase.length === list.length) return;
  //   await this.toLS("Content", indbase);
  //   list = await this.getListContent(el.listid);
  //   indbase.sort((a, b) => a.num - b.num);
  //   indbase.sort((a, b) => a.day - b.day);

  //   let day = 0;
  //   let i = 0;
  //   let updarr = [];
  //   indbase.forEach((element) => {
  //     if (day !== element.day) {
  //       i = 0;
  //       day = element.day;
  //     }
  //     if (element.num !== i) updarr.push({ ...element, num: i });
  //     i++;
  //   });

  //   await this.updateContentArr(updarr);
  // },

  // async getUser() {
  //   let token = JSON.parse(localStorage.getItem("tokenschedule"));
  //   if (!token) return undefined;
  //   let usersList = JSON.parse(localStorage.getItem("users"));
  //   let user = usersList.filter((item) => item.sessions.includes(token));
  //   return user[0];
  // },
  // async getListArr() {
  //   let list = await this.fromLS("List");
  //   return list;
  // },
  // async getList(id) {
  //   if (!id) return undefined;
  //   let list = await this.fromLS("List");
  //   let indbase = list.filter((item) => item.id.toString() === id.toString());

  //   return indbase.length ? indbase[0] : undefined;
  // },

  // async getOrAddS(listid, name) {
  //   let listS = await this.fromLS("Subjects");
  //   let numS = listS.findIndex(
  //     (item) =>
  //       item.name.toString() === name.toString() &&
  //       item.listid.toString() === listid.toString()
  //   );
  //   let idS = +new Date();
  //   if (numS === -1) {
  //     listS.push({
  //       id: idS,
  //       listid: listid,
  //       name: name,
  //       col: "",
  //     });

  //     await this.toLS("Subjects", listS);
  //   } else idS = listS[numS].id;
  //   return idS;
  // },
  // async getListContent(id) {
  //   let list = await this.fromLS("Content");
  //   let indbase = list.filter(
  //     (item) => item.listid.toString() === id.toString()
  //   );
  //   return indbase ? indbase : [];
  // },
  // async getListSubj(id) {
  //   let list = await this.fromLS("Subjects");
  //   let indbase = list.filter(
  //     (item) => item.listid.toString() === id.toString()
  //   );
  //   return indbase;
  // },
  // async getNotesByDate(date1, date2, id) {
  //   let dt1 = newDateFormat(date1);
  //   let dt2 = newDateFormat(date2);
  //   let list = await this.fromLS("Notes");
  //   let indbase = list.filter(
  //     (item) =>
  //       dt1 < newDateFormat(item.date) < dt2 &&
  //       item.listid.toString() === id.toString()
  //   );
  //   return indbase ? indbase : [];
  // },
  // async updateUser(ud) {
  //   let token = JSON.parse(localStorage.getItem("tokenschedule"));
  //   if (!token) return undefined;
  //   let usersList = JSON.parse(localStorage.getItem("users"));
  //   let num = usersList.findIndex((item) => item.sessions.includes(token));
  //   usersList[num] = {
  //     ...usersList[num],
  //     name: ud.name,
  //     email: ud.email,
  //     password: ud.password,
  //   };
  //   await this.toLS("users", usersList);
  //   return true;
  //   // let user = usersList.filter((item) => item.sessions.includes(token));
  //   // return user[0];
  // },
  // async updateList(id, newSet) {
  //   // for rename subj

  //   let list = await this.fromLS("List");
  //   let num = list.findIndex((item) => item.id.toString() === id.toString());
  //   list[num] = { ...list[num], ...newSet }; //name: newC
  //   // if (newName) {
  //   //   let idS = await this.getOrAddS(el.listid, newName);
  //   //   list[num] = { ...list[num], subid: idS }; //name: newC
  //   // }

  //   await this.toLS("List", list);
  // },
  // async updateSubj(id, newSet) {
  //   // for rename subj
  //   let list = await this.fromLS("Subjects");
  //   let num = list.findIndex((item) => item.id.toString() === id.toString());
  //   list[num] = { ...list[num], ...newSet }; //name: newC
  //   // if (newName) {
  //   //   let idS = await this.getOrAddS(el.listid, newName);
  //   //   list[num] = { ...list[num], subid: idS }; //name: newC
  //   // }

  //   await this.toLS("Subjects", list);
  // },
  // async updateContent(el, newName = "") {
  //   // for rename subj
  //   let list = await this.fromLS("Content");
  //   let num = list.findIndex((item) => item.id.toString() === el.id.toString());
  //   list[num] = { ...list[num], ...el }; //name: newC
  //   if (newName) {
  //     let idS = await this.getOrAddS(el.listid, newName);
  //     list[num] = { ...list[num], subid: idS }; //name: newC
  //   }

  //   await this.toLS("Content", list);
  // },
  // async updateContentArr(updArr) {
  //   // for reordering
  //   let list = await this.fromLS("Content");
  //   updArr.forEach((element) => {
  //     let num = list.findIndex(
  //       (item) => item.id.toString() === element.id.toString()
  //     );
  //     list[num] = { ...list[num], ...element }; //name: newC
  //   });

  //   await this.toLS("Content", list);
  // },

  async login(login, passw) {
    let usersList = await this.fromLS("users");
    let userInd = usersList.findIndex(
      (item) => item.email.trim() === login.trim()
    );
    let user = usersList[userInd];
    if (user === undefined) return { status: false, error: "user is not find" };
    if (user.password !== passw)
      return { status: false, error: "wrong password" };
    let token = +new Date();
    usersList[userInd].sessions.push(token);
    localStorage.setItem("users", JSON.stringify(usersList));
    localStorage.setItem("tokenschedule", JSON.stringify(token));
    return {
      status: true,
    };
  },
  logout() {
    localStorage.removeItem("token");
  },
  createDB(name) {
    switch (name) {
      case "List":
        localStorage.setItem(name, JSON.stringify(dataBase.list));
        break;
      case "Users":
        localStorage.setItem(name, JSON.stringify(dataBase.users));
        break;
      case "User":
        localStorage.setItem(name, JSON.stringify(dataBase.user));
        break;
      case "Content":
        localStorage.setItem(name, JSON.stringify(dataBase.content));
        break;
      case "Subjects":
        localStorage.setItem(name, JSON.stringify(dataBase.subjects));
        break;
      case "Notes":
        localStorage.setItem(name, JSON.stringify(dataBase.notes));
        break;
      default:
        break;
    }
  },
};
export default ServerAPI;
