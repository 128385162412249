import React, { useState } from "react";

import MyInputGroup from "../../UI/MyInputGroup";

const TimeInputGroup = ({ time, i, onchange, br, last }) => {
  const [newTime, setNewTime] = useState(time ? time : "");

  return (
    <div className=" d-flex w-80 ms-1 me-1">
      <MyInputGroup
        cn="mb-1"
        type="time"
        label={i + 1}
        required
        size="sm"
        value={newTime}
        onChange={(e) => {
          if (!e.target.value) return;
          setNewTime(e.target.value);

          onchange(e, i, true);
        }}
        onKeyPress={(e) => {
          if (e.key !== "Enter") return;
          onchange(e, i, true);
        }}
        onBlur={(e) => {
          if (!e.relatedTarget || !e.target.value) return;
          setNewTime(e.target.value);
        }}
      />
      {last && (
        <MyInputGroup
          cn="mb-1"
          type="Number"
          label={"break " + (i + 1)}
          required
          size="sm"
          value={br}
          onChange={(e) => {
            onchange(e, i);
          }}
          onKeyPress={(e) => {
            if (e.key !== "Enter") return;
            onchange(e, i);
          }}
        />
      )}
    </div>
  );
};

export default TimeInputGroup;
