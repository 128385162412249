import { BaseAPI } from "../API/BaseApi";
import { dragDrop } from "./arraysFunc";

export const deleteLesson = async (item, subjects) => {
  await BaseAPI.deleteLesson(item.id);
  let newarr = [...subjects];
  const list = [...subjects[item.weekDay]];
  list.splice(item.ordNum, 1);
  newarr[item.weekDay] = list;
  return newarr;
};

export const delEl = async (item, lessons, callback) => {
  let newa = await deleteLesson(item, lessons);
  await callback(newa);
};
export const moveEl = async (dragFrom, dragTo, lessons, callback) => {
  // if (!dragTo.current.color) {
  if (dragFrom.current === dragTo.current) return;
  if (dragTo.current.hasOwnProperty("weekDay")) {
    const [updArr, newarr] = dragDrop(
      dragFrom.current,
      dragTo.current,
      lessons
    );
    await BaseAPI.updateLessonsArr(updArr);
    callback(newarr);
  } else {
    // await deleteLesson(dragFrom.current, lessons);
    await delEl(dragFrom.current, lessons, callback);
  }
};
// export const moveElold = async (dragFrom, dragTo, lessons, callback) => {
//   // if (!dragTo.current.color) {
//   if (dragFrom.current === dragTo.current) return;
//   if (dragTo.current.hasOwnProperty("weekDay")) {
//     const [updArr, newarr] = dragDrop(
//       dragFrom.current,
//       dragTo.current,
//       lessons
//     );
//     await BaseAPI.updateLessonsArr(updArr);
//   } else {
//     // await deleteLesson(dragFrom.current, lessons);
//     await deleteLesson(dragFrom.current, lessons);
//   }
//   callback();
// };

export const addMoveEl = async (
  dragTo,
  dragFrom,
  id,
  weekNumber,
  callback,
  lessons
) => {
  if (!dragTo) return;
  // let num;
  // let num = !dragTo.hasOwnProperty("id")
  // ? dragTo.current.ordNum
  // : lessons[dragTo.current.weekDay].length;

  if (dragTo.current.id) return;
  let newid = await BaseAPI.createLesson(
    dragFrom.current.name,
    id,
    dragTo.current.ordNum,
    dragTo.current.weekDay,
    weekNumber,
    dragFrom.current.id
  );
  let newel = {
    color: dragFrom.current.color,
    id: newid.id,
    ordNum: dragTo.current.ordNum,
    scheduleid: id,
    subjectName: dragFrom.current.name,
    subjectid: dragFrom.current.id,
    weekDay: dragTo.current.weekDay,
    weekNumber: weekNumber,
  };

  let newarr = [...lessons];
  newarr[newel.weekDay][newel.ordNum] = newel;
  callback(newarr);
};

// export const addMoveEl = async (dragTo, dragFrom, id, weekNumber, callback) => {
//   if (!dragTo) return;
//   // let num;
//   // let num = !dragTo.hasOwnProperty("id")
//   // ? dragTo.current.ordNum
//   // : lessons[dragTo.current.weekDay].length;
//   if (dragTo.current.id) return;
//   await BaseAPI.createLesson(
//     dragFrom.current.name,
//     id,
//     dragTo.current.ordNum,
//     dragTo.current.weekDay,
//     weekNumber,
//     dragFrom.current.id
//   );
//   callback();
// };
export const getDragDropPropsPlan = (drag, item) => {
  const touchEnd = (e) => {
    var myLocation = e.changedTouches[0];
    var realTarget = document.elementFromPoint(
      myLocation.clientX,
      myLocation.clientY
    );
    let dt = realTarget.getAttribute("dataday");
    let nm = realTarget.getAttribute("datanum");
    drag.dragTo.current = { day: dt, num: nm };
    drag.move();
  };
  return {
    draggable: true,
    onDragStart: (e) => (drag.dragFrom.current = item),
    onDragEnd: drag.move,
    onDragEnter: (e) => (drag.dragTo.current = item),
    onTouchStart: (e) => (drag.dragFrom.current = item),
    onTouchEnd: touchEnd,
  };
};
