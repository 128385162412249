import React from "react";
import { Dropdown } from "react-bootstrap";

const MyDropDownMenu = ({ arr, title, ...props }) => {
  return (
    <Dropdown className="d-flex">
      <Dropdown.Toggle
        id="dropdown-autoclose-true"
        variant="outline-success"
        style={{ borderRadius: "0", height: "100%" }}>
        {title}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {arr.map((el, i) =>
          el === "Divider" ? (
            <Dropdown.Divider key={"dev" + i} />
          ) : (
            <Dropdown.Item key={i} onClick={el.onClick}>
              {el.name}
            </Dropdown.Item>
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MyDropDownMenu;
