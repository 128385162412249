import React from "react";
import { titlesHash } from "../../utils/hash";
import cl from "./diary.module.scss";

const BtnTitles = ({ viewMode, setViewMode }) => {
  const changeView = () => {
    titlesHash(1 - viewMode.mode, 1);
    setViewMode({ ...viewMode, mode: 1 - viewMode.mode });
  };
  const changeDirection = () => {
    setViewMode({
      ...viewMode,
      direction: viewMode.direction === "column" ? "row" : "column",
    });
  };
  return (
    <div className={cl["btns-title"]}>
      <button onClick={changeDirection} className={cl.btnView}>
        {viewMode.direction === "column" ? "|" : "—"}
      </button>{" "}
      <button onClick={changeView} className={cl.btnView}>
        {viewMode.mode === 1 ? "ᐅ" : "ᐁ"}
      </button>
    </div>
  );
};

export default BtnTitles;
