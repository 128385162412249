import React from "react";
import { ButtonGroup } from "react-bootstrap";
import OneButton from "../UI/Buttons/OneButton";

const WeeksNumber = ({ title, mode, switchWeek }) => {
  console.log("title", title);

  const weeksNumb = Array.from(
    { length: Number(title.weeksNumber.weeks) },
    (_, i) => i + 1
  );
  return (
    <ButtonGroup>
      {weeksNumb.map((el, i) => (
        <OneButton
          key={"wn" + el}
          title={el}
          outline={el !== mode.week}
          onClick={(e) => {
            switchWeek(el);
          }}
        />
      ))}
    </ButtonGroup>
  );
};

export default WeeksNumber;
//  title.weeksNumber
