import React from "react";
import { ToggleButton } from "react-bootstrap";

const OneToggleButton = ({ title, outline = false, ...props }) => {
  return (
    <ToggleButton variant={outline ? "outline-success" : "success"} {...props}>
      {title}
    </ToggleButton>
  );
};

export default OneToggleButton;
