import React from "react";
import { Dropdown } from "react-bootstrap";

const SubjItemsList = ({ list, selected, onSelect }) => {
  return (
    <>
      {list.length > 0 &&
        list.map((item) => (
          <Dropdown.Item
            style={{ fontSize: "1.5rem" }}
            active={selected.id === item.id}
            key={item.id}
            eventKey={item.id}
            onClick={() => {
              onSelect(item);
            }}>
            {item.name}
          </Dropdown.Item>
        ))}
    </>
  );
};

export default SubjItemsList;
