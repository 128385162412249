import React from "react";
import { getEnd } from "../../utils/timeStr";
import cl from "../Schedule/schedule.module.scss";
import clp from "./plan.module.scss";

const LessNum = ({ mode, sch, switchMode }) => {
  const max = Array.from({ length: Number(sch.maxLessons) }, (_, i) => i + 1);
  const bodyclass = [clp["oneLess" + mode.view], cl.num, clp.colorBg].join(" ");

  return (
    <div className={mode.view === "card" ? clp.oneDayC : clp.oneDay}>
      <div onClick={switchMode} className={clp.cornerBtn}>
        {mode.view === "card" ? "ᐅ" : "ᐁ"}
      </div>
      {max.map((item, i) => (
        <div key={i} className={bodyclass}>
          <span>{item} </span>
          {sch.timeTable[i] +
            "-" +
            getEnd(sch.timeTable[i], sch.lessonDuration)}
        </div>
      ))}
    </div>
  );
};

export default LessNum;
