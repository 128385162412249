import React from "react";
import { Form } from "react-bootstrap";
import { DateFormatYYYYMMDD, nextDate } from "../../utils/date";
import IntervalWeek from "./IntervalWeek";
import cl from "./diary.module.scss";

const IntervalNav = ({ interval, time, setTime }) => {
  const nextD = (direction) => {
    setTime(nextDate(interval, time, direction), direction);
  };

  return (
    <div style={{ width: "19rem" }} className={cl["wrap-intervalBox"]}>
      <button
        title={"<"}
        className={cl.interval_btnLeft}
        onClick={(e) => nextD(-1)}>
        {"<"}
      </button>
      {interval === "month" ? (
        <Form.Control
          className={cl.intervalInput}
          type="month"
          value={time}
          onChange={(e) => setTime(e.target.value)}
        />
      ) : interval === "week" ? (
        <IntervalWeek time={time} setTime={setTime} />
      ) : (
        <Form.Control
          style={{ textAlign: "center" }}
          className={cl.intervalInput}
          type="date"
          value={DateFormatYYYYMMDD(time)}
          onChange={(e) => setTime(e.target.value)}
        />
      )}
      <button
        title={">"}
        className={cl.interval_btnRight}
        onClick={(e) => nextD(1)}>
        {">"}
      </button>
    </div>
  );
};

export default IntervalNav;
