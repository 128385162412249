import React from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import DiaryMonth from "./Month/DiaryMonth";
import DiaryDay from "./WeekOrDay/DiaryDay";
import DiaryWeek from "./WeekOrDay/DiaryWeek";
const DiaryBody = ({
  dataSch,
  interval = "month",
  time,
  notes,
  anim,
  animClass,
}) => {
  const component =
    interval === "month" ? (
      <DiaryMonth dataSch={dataSch} dateRange={time} dataNotes={notes} />
    ) : interval === "week" ? (
      <DiaryWeek dataSch={dataSch} dateRange={time} dataNotes={notes} />
    ) : (
      <DiaryDay dataSch={dataSch} dateRange={time} dataNotes={notes} />
    );

  return (
    <>
      <div style={{ display: animClass === "interval" ? "block" : "none" }}>
        <SwitchTransition>
          <CSSTransition timeout={500} classNames="interval" key={anim}>
            <div>{time && component}</div>
          </CSSTransition>
        </SwitchTransition>
      </div>
      <div style={{ display: animClass === "timeLeft" ? "block" : "none" }}>
        <SwitchTransition>
          <CSSTransition timeout={300} classNames="timeLeft" key={anim}>
            <div>{time && component}</div>
          </CSSTransition>
        </SwitchTransition>
      </div>
      <div style={{ display: animClass === "timeRight" ? "block" : "none" }}>
        <SwitchTransition>
          <CSSTransition timeout={300} classNames="timeRight" key={anim}>
            <div>{time && component}</div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </>
  );
};

export default DiaryBody;
