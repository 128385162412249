import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import MyModal from "../UI/MyModal";
import OneButton from "../UI/Buttons/OneButton";

const EditBox = ({ value, setValue, title, ok, esc, del, col, ...props }) => {
  return (
    <MyModal title={title ? title : "Edit"} setshow={esc} show={true}>
      <div>
        <InputGroup className="mb-1 w-100" size="sm">
          <Form.Control
            autoFocus
            placeholder={props.placeholder}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter") ok();
            }}
          />
          {col && (
            <Form.Control
              type="color"
              defaultValue={col.color}
              title="Choose your color"
              onChange={(e) => col.setColor(e.target.value)}
            />
          )}

          <OneButton title="✔" onClick={ok} outline={true} />

          {del && <OneButton title=" 🗑" onClick={del} outline={true} />}
        </InputGroup>
      </div>
    </MyModal>
  );
};

export default EditBox;
