import React from "react";

import { useNavigate } from "react-router-dom";
import { BaseAPI } from "../../API/BaseApi";
import UserProfile from "./UserProfile";

const SignUp = () => {
  const router = useNavigate();

  const newUser = (data) => {
    let ok = BaseAPI.createUser(data);
    if (!ok.error) {
      router("/login/" + data.email);
    }
  };
  return (
    <div>
      <UserProfile onClick={newUser} btnName="Sign up"></UserProfile>
    </div>
  );
};

export default SignUp;
