import React from "react";
import { useState } from "react";
import { BaseAPI } from "../../API/BaseApi";
import { getDragDropPropsPlan } from "../../utils/dragDrop";
import EditBoxPlan from "./EditBoxPlan";
import clp from "./plan.module.scss";

const LessonBox = ({ item, del, drag, update, mode }) => {
  const [editMode, setEditMode] = useState(false);

  const editSwitch = (val = !editMode) => {
    setEditMode(val);
  };

  const editOf = () => {
    setEditMode(false);
  };

  const updatePage = async (newSub) => {
    if (!newSub) return;

    if (newSub.id && newSub.id === item.subjectid) return;
    await BaseAPI.updateLesson(item, newSub.name);
    setEditMode(!editMode);
    await update.getLess();
  };
  const stcolor = {
    backgroundColor: item.color,
    touchAction: "none",
  };

  return (
    <div className={clp.classWrap}>
      {editMode && (
        <EditBoxPlan
          value={item}
          ok={updatePage}
          esc={editOf}
          del={() => {
            editSwitch();
            del(item);
          }}
        />
      )}{" "}
      <div
        key={item.id}
        className={clp["oneLess" + mode]}
        style={stcolor}
        dataday={item.weekDay}
        datanum={item.ordNum}
        {...getDragDropPropsPlan(drag, item)}
        onClick={() => {
          setEditMode(item);
        }}>
        {item.subjectName}
      </div>
    </div>
  );
};

export default LessonBox;
