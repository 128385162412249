import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { BaseAPI } from "../../API/BaseApi";
import { useLoading } from "../../hooks/useLoading";
import Sidebar from "../UI/SideBar/Sidebar";
import cl from "./plan.module.scss";
import SubjectsList from "./SubjectsList";

const Settings = ({ delLessSubg, drag, setlesscolor, getLess }) => {
  const [showSide, setShowSide] = useState(false);
  const id = useParams().id;
  const [subList, setSubList] = useState([]);

  const [getSubjList, isLoadingSub] = useLoading(async () => {
    const res = await BaseAPI.getSubjects(id);
    res.sort((a, b) => a.name.localeCompare(b.name));
    setSubList(res);
  });
  useEffect(() => {
    getSubjList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const reload = async (...arg) => {
    await getSubjList();
    if (arg.length === 2) setlesscolor(...arg);
    else await delLessSubg(...arg);
    getLess();
  };
  const nodeRef = useRef(null);
  return (
    <div className={showSide ? cl.subjWrapShow : cl.subjWrap}>
      <button
        title="Subjects"
        outline={true}
        className={cl.subjBtn}
        onClick={() => {
          setShowSide(!showSide);
        }}>
        Subjects
      </button>
      {!isLoadingSub && (
        <CSSTransition
          in={showSide}
          nodeRef={nodeRef}
          timeout={500}
          classNames="sidebar"
          unmountOnExit>
          <div ref={nodeRef}>
            <Sidebar>
              <SubjectsList subj={subList} drag={drag} reload={reload} />
            </Sidebar>
          </div>
        </CSSTransition>
      )}
    </div>
  );
};

export default Settings;
