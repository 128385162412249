import React, { useState, useEffect } from "react";

import {
  defineTimeNow,
  defineTimeSelect,
  getMonthNow,
  getRange,
} from "../../utils/date";

import { BaseAPI } from "../../API/BaseApi";
import MySpinner from "../UI/MySpinner";
import { CSSTransition } from "react-transition-group";
import { useLoading } from "../../hooks/useLoading";
import DateRange from "../Nav/DateRange";
import NotesBody from "../Notes/NotesBody";
import IntervalNav from "../Diary/IntervalNav";
import DiaryBody from "../Diary/DiaryBody";

const DiaryNotes = ({ schedule, page }) => {
  const [interval, setInterval] = useState("month");
  const [selectedTime, setSelectedTime] = useState(getMonthNow());
  const [viewMode, setViewMode] = useState({ mode: 0, direction: "column" });
  const [lessons, setLessons] = useState([]);
  const [notes, setNotes] = useState(null);
  const [anim, setAnim] = useState(false);
  const [animClass, setAnimClass] = useState("interval");

  const getNotes = async (time = selectedTime, int = interval) => {
    let z = getRange(schedule, time, int);
    const res = await BaseAPI.getNotesByDate(...z, schedule.id);
    if (!res.error) setNotes(res);
  };
  const selectInterval = (val) => {
    if (val === interval) return;
    let timeNow = defineTimeNow(val, schedule);
    setInterval(val);
    setSelectedTime(timeNow);
    getNotes(timeNow, val);
    setAnimClass("interval");
    setAnim(!anim);
  };
  const selectTime = (val, dir) => {
    let timeSelected = defineTimeSelect(interval, val, schedule);
    setSelectedTime(timeSelected);
    getNotes(timeSelected);
    let animc = !dir ? "interval" : dir === -1 ? "timeLeft" : "timeRight";
    setAnimClass(animc);
    setAnim(!anim);
  };
  const [getScheduleAndLessons, isLoading] = useLoading(async () => {
    // const res = await BaseAPI.getSchedule(schedule.id);
    if (!schedule) return;
    // setSchedule(res);
    const resLess = await BaseAPI.getLessons(schedule.id);

    if (!resLess) return;
    setLessons(resLess);
    let z = getRange(schedule, selectedTime, interval);
    const resN = await BaseAPI.getNotesByDate(...z, schedule.id);

    if (resN) setNotes(resN);
  });

  useEffect(() => {
    getScheduleAndLessons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const time = (
    <IntervalNav interval={interval} time={selectedTime} setTime={selectTime} />
  );
  console.log(lessons);

  return (
    <CSSTransition appear={true} in={true} timeout={500} classNames="page">
      <div style={{ position: "relative" }}>
        {isLoading || !schedule || !lessons.length ? (
          <MySpinner />
        ) : (
          <>
            <DateRange
              page={page}
              setInt={selectInterval}
              timeInt={time}
              viewMode={viewMode}
              setViewMode={setViewMode}
            />
            <br /> <br />
            <>
              {page === "diary" ? (
                <DiaryBody
                  anim={anim}
                  animClass={animClass}
                  notes={{ notes: notes, getNotes: getNotes }}
                  interval={interval}
                  time={selectedTime}
                  dataSch={{
                    mode: viewMode.mode,
                    direction: viewMode.direction,
                    lessons: lessons,
                    sch: schedule,
                  }}
                />
              ) : (
                <NotesBody notes={notes} getNotes={getNotes} />
              )}
            </>
          </>
        )}{" "}
      </div>
    </CSSTransition>
  );
};

export default DiaryNotes;
