import React from "react";
import AvatarGalery from "./AvatarGalery";
import Image from "react-bootstrap/Image";
import cl from "./users.module.css";

const ProfileImg = (props) => {
  const changeAvatar = (url) => {
    props.setUserDataForm({ ...props.userDataForm, img: url });
  };
  return (
    // <div className="d-flex justify-content-center mt-2 px-5">
    <div className={cl.avatarDiv}>
      {props.visible && (
        <AvatarGalery
          visible={props.visible}
          setVisible={props.setVisible}
          fileChange={changeAvatar}
        />
      )}
      <div>
        <Image
          rounded
          src={props.userDataForm.img}
          className={cl.avatarProfile}
          onClick={() => {
            props.setVisible(true);
          }}
        />
      </div>
    </div>
    // </div>
  );
};

export default ProfileImg;
