import React from "react";
import OneDay from "./OneDay";
import { defineCurrentWeek } from "../../../utils/date";

const DiaryDay = ({ dataSch, dateRange, dataNotes }) => {
  const curweek = defineCurrentWeek(
    dataSch.sch.weeksNumber,
    dataSch.sch.startDay,
    dateRange
  );
  return (
    <>
      {dateRange instanceof Date && (
        <div className="d-flex flex-wrap justify-content-center mt-4">
          <OneDay
            dataSch={dataSch}
            day={dateRange.getDay()}
            date={dateRange}
            dataNotes={dataNotes}
            weekstyle="column"
            week={curweek.current}
          />
        </div>
      )}
    </>
  );
};

export default DiaryDay;
