import React, { useState } from "react";
import { useParams } from "react-router-dom";
import clp from "./plan.module.scss";
import { BaseAPI } from "../../API/BaseApi";
import EditBoxPlan from "./EditBoxPlan";
import { IoMdAdd } from "react-icons/io";

const NewLesson = ({ day, max, drag, update, mode }) => {
  const [creationMode, setCreationMode] = useState(false);
  const id = useParams().id;

  const add = async (newSub) => {
    if (!newSub) return;
    if (!Object.keys(newSub).length) return;
    await BaseAPI.createLesson(newSub.name, id, max, day, mode.week);
    setCreationMode(false);

    update.getLess();
  };
  const editOf = () => {
    setCreationMode(false);
  };

  return (
    <>
      {creationMode && <EditBoxPlan value={""} ok={add} esc={editOf} />}
      <div className={clp.classWrap}>
        <div
          className={[clp["oneLess" + mode.view], clp.addNewBtn].join(" ")}
          // className={["card, mt-1", clp["oneLess" + mode.view]].join(" ")}
          onClick={() => setCreationMode(true)}
          dataday={day}
          datanum={0}
          outline={true}
          onDragEnd={drag.move}
          title="add new"
          onDragEnter={(e) => {
            if (drag.dragTo)
              drag.dragTo.current = { weekDay: day, ordNum: max };
          }}>
          <IoMdAdd />
        </div>
        {/* <OneButton
          className={"card, mb-1 " + clp["oneLess" + mode.view] + " mt-1"}
          onClick={() => setCreationMode(true)}
          dataday={day}
          datanum={0}
          outline={true}
          onDragEnd={drag.move}
          title="add new"
          onDragEnter={(e) => {
            if (drag.dragTo)
              drag.dragTo.current = { weekDay: day, ordNum: max };
          }}
        /> */}
      </div>
    </>
  );
};

export default NewLesson;
