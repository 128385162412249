import React from "react";
import OneNote from "./OneNote";

const NotesBody = ({ notes, getNotes }) => {
  return (
    <>
      {notes && notes.length === 0 && (
        <div className="text-center ">
          <h1 className="fs-1 ">NO NOTES</h1>
        </div>
      )}
      {notes &&
        notes.map((item, i) => (
          <OneNote
            key={i}
            note={item}
            j={i}
            title={"notes"}
            update={getNotes}
          />
        ))}
    </>
  );
};

export default NotesBody;
