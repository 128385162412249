import React from "react";
import BtnTitles from "../Diary/BtnTitles";

const DateRange = ({ page, setInt, timeInt, viewMode, setViewMode }) => {
  const changeInt = (int) => {
    setInt(int);
    window.location.hash = int + "_" + viewMode.mode + viewMode.direction;
  };

  const menu = ["month", "week", "day"];
  return (
    <div className="string_submenu">
      {/* <BtnsNav title={title} page="diary" /> */}
      <div className="interval_wrap">
        {timeInt} {/* <div className="d-flex"> */}
        {/* <Form.Label className="mt-1">period</Form.Label> */}
        <div className="interval_box">
          {menu.map((el, i) => (
            <>
              <div
                id={i}
                onClick={() => changeInt(el)}
                className={
                  timeInt.props.interval === el
                    ? "interval interval_active"
                    : "interval "
                }>
                {el}
              </div>
            </>
          ))}
        </div>
      </div>

      {page === "diary" && (
        <BtnTitles viewMode={viewMode} setViewMode={setViewMode} />
      )}
    </div>
  );
};

export default DateRange;
/* <UserAvatar style={{ width: "30px", height: "30px" }} /> */
