import React from "react";
import { getDragDropPropsPlan } from "../../utils/dragDrop";

const SubjCardBase = ({ item, drag, setNewSub, setEditMode }) => {
  return (
    <div
      className="card  mb-1"
      style={{
        backgroundColor: item.color ? item.color : "#f8f1f8",
        touchAction: "none",
      }}
      {...getDragDropPropsPlan(drag, item)}
      onClick={() => {
        setEditMode(item.id);
        setNewSub(item.name);
      }}>
      ({item.name})
    </div>
  );
};

export default SubjCardBase;
