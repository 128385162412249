/* eslint-disable no-unused-vars */
import React from "react";
import Image from "react-bootstrap/Image";
import { useState, useEffect } from "react";

import MySpinner from "../UI/MySpinner";
import { BaseAPI } from "../../API/BaseApi";
import { useLoading } from "../../hooks/useLoading";
import { useNavigate } from "react-router-dom";

const UserAvatar = (props) => {
  const [av, setAv] = useState();
  const [getData, isLoading] = useLoading(async () => {
    let userData = await BaseAPI.getUser();

    if (userData) setAv(userData.img);
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const router = useNavigate();
  return isLoading ? (
    <MySpinner />
  ) : (
    <Image
      onClick={() => router("/profile")}
      rounded
      src={av}
      style={{ width: "8%", height: "8%" }}
      {...props}
    />
  );
};

export default UserAvatar;
