import React from "react";
import { Form } from "react-bootstrap";
import cl from "./diary.module.scss";
import { FaCalendarWeek } from "react-icons/fa";
const IntervalWeek = ({ time, setTime }) => {
  return (
    <>
      {time && (
        <div className="tsp-wrap">
          <Form.Control
            type="text"
            className={"form_border_r " + cl.intervalInputWeek}
            value={
              time[0].toString().slice(0, 10) +
              "-" +
              time[1].toString().slice(0, 10)
            }
            onChange={(e) => {}}
          />

          <div className="div_tsp_input">
            <button className={cl.btn_picker}>
              <FaCalendarWeek />
            </button>
            <input
              type="week"
              className="tsp_input"
              value={time[2]}
              onChange={(e) => setTime(e.target.value)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default IntervalWeek;
