import React from "react";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import MyNavLink from "./MyNavLink";
import cl from "./MyNavbar.module.scss";
import { useAuth } from "../../hooks/useAuth";
import ServerAPI from "../../API/ServerAPI";
import UserAvatar from "../users/UserAvatar";
import OneButton from "../UI/Buttons/OneButton";

const MyNavbar = ({ children = <></> }) => {
  const router = useNavigate();
  //get  elements with nameNav only
  const [routesArr, userAuth, setUserAuth] = useAuth(true);

  const logout = () => {
    ServerAPI.logout();
    setUserAuth({ isAuth: false, role: null });
    router("/login");
  };
  return (
    <>
      <div className={[cl.nav, "bg-light"].join(" ")}>
        <Nav activeKey="/schedule" className="justify-content-between  ">
          {routesArr
            .filter((el) => el.nameNav)
            .map((item, i) => (
              <Nav.Item key={i}>
                <MyNavLink root={item} />
              </Nav.Item>
            ))}
          {children}
          {userAuth.isAuth && (
            <div>
              <UserAvatar style={{ width: "30px", height: "30px" }} />
              <OneButton
                title="Logout"
                outline={true}
                size="md"
                onClick={logout}
              />
            </div>
          )}
        </Nav>
      </div>
    </>
  );
};

export default MyNavbar;
