import React, { useState } from "react";
import cl from "../diary.module.scss";
import clweek from "./diaryweek.module.css";
import { Card } from "react-bootstrap";
import { noteById } from "../../../utils/arraysFunc";
import OneLesson from "./OneLesson";
import OneDayModal from "../OneDayModal";
import { weekDayNames } from "../../../utils/date";

const OneDay = ({ dataSch, dataNotes, date, weekstyle = "row", week }) => {
  const day = date.getDay();

  const [showDay, setShowDay] = useState(false);

  const dayTitle = date.toISOString().slice(0, 10) + " " + weekDayNames[day];

  return (
    <>
      {showDay ? (
        <OneDayModal
          setVisible={setShowDay}
          notes={dataNotes.notes}
          dataSch={{ ...dataSch, day: day, mode: 1 }}
          update={dataNotes.getNotes}
          date={date}
          weekNum={week}
        />
      ) : (
        <Card bg="Light" dataday={day} className={clweek["card" + weekstyle]}>
          <Card.Header
            className={cl["dayCHeadMode" + dataSch.mode]}
            onClick={() => {
              setShowDay(true);
            }}>
            {dayTitle} <sup className="fw-light">week{week}</sup>
          </Card.Header>
          {dataSch.mode === 0 && (
            <Card.Body className="p-0 ">
              {dataSch.lessons[day][week - 1] &&
                dataSch.lessons[day][week - 1].map((item, j) => (
                  <OneLesson
                    key={j}
                    note={noteById(dataNotes.notes, item.id, date)}
                    item={item}
                    j={j}
                    title={dayTitle}
                    update={dataNotes.getNotes}
                    weekstyle={weekstyle}
                  />
                ))}
            </Card.Body>
          )}
        </Card>
      )}
    </>
  );
};

export default OneDay;
