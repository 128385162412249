import React, { useMemo } from "react";
import NewLesson from "./NewLesson";
import cl from "./plan.module.scss";
import LessonBox from "./LessonBox";
import { weekDayNames } from "../../utils/date";

const Day = ({ data, update, del, drag }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const arrLessMax = useMemo(() => Array(data.sch.maxLessons).fill(1), []);

  return (
    <div className={data.mode.view === "card" ? cl.oneDayC : cl.oneDay}>
      <div className={[cl.cardHeader, cl.colorBg].join(" ")}>
        {weekDayNames[data.day]}
      </div>
      {data.lessons &&
        arrLessMax.map((_, i) =>
          data.lessons.length > i && data.lessons[i] ? (
            <LessonBox
              key={data.lessons[i].id}
              item={data.lessons[i]}
              del={del}
              drag={drag}
              update={update}
              mode={data.mode.view}
            />
          ) : (
            <NewLesson
              key={"n" + i}
              day={data.day}
              update={update}
              max={i}
              drag={drag}
              mode={data.mode}
            />
          )
        )}{" "}
    </div>
  );
};

export default Day;
