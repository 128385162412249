import React from "react";
import cl from "./MyNavbar.module.scss";

const BtnsNav = ({ page, setPage }) => {
  const onClickPlan = () => setPage("plan");
  const onClickNotes = () => setPage("notes");
  const onClickDiary = () => setPage("diary");

  return (
    <div className={cl["btn_wrap"]}>
      <button
        onClick={onClickPlan}
        className={[
          cl["_left"],
          page === "plan" ? cl["btn-part_active"] : "",
        ].join(" ")}>
        Plan
      </button>
      <button
        onClick={onClickNotes}
        className={[
          cl["btn_part"],
          page === "notes" ? cl["btn-part_active"] : "",
        ].join(" ")}>
        Notes
      </button>
      <button
        onClick={onClickDiary}
        className={[
          cl["_right"],
          page === "diary" ? cl["btn-part_active"] : "",
        ].join(" ")}>
        Diary
      </button>
    </div>
  );
};

export default BtnsNav;
