import React, { useState } from "react";

import clnotes from "./notes.module.css";
import cl from "../Diary/diary.module.scss";
import EditBox from "../Schedule/EditBox";

import { BaseAPI } from "../../API/BaseApi";

const OneNote = ({ j, note, title, update, weekstyle = "row" }) => {
  console.log(note);

  const [editMode, setEditMode] = useState(false);
  const [newNote, setNewNote] = useState(note ? note : "");
  const editSwitch = (val = !editMode) => {
    setEditMode(val);
  };
  const editOf = () => {
    setEditMode(false);
  };
  const del = async (nt) => {
    await BaseAPI.deleteNote(nt.id);
    await update();
  };
  const updatePage = async () => {
    if (!newNote) return;
    await BaseAPI.updateNote(newNote.id, newNote.note);
    setEditMode(!editMode);
    await update();
  };

  const oneLess = (j) => {
    let st = { backgroundColor: note.color };
    return (
      <div
        onClick={editSwitch}
        className={clnotes.oneLesson + " " + cl.lessBoxC}>
        <div className={clnotes["subj" + weekstyle]} style={st}>
          {note.day}
        </div>
        <div className={clnotes["subj" + weekstyle]} style={st}>
          {note.subjectName}
        </div>
        <div className={clnotes.note} style={note ? st : {}}>
          {note.note}
        </div>
      </div>
    );
  };

  return (
    <>
      {editMode && (
        <EditBox
          value={newNote.note}
          setValue={(v) => setNewNote({ ...newNote, note: v })}
          ok={updatePage}
          esc={editOf}
          title={title + ": " + note.subjectName}
          del={() => {
            editSwitch();
            del(newNote);
          }}
        />
      )}{" "}
      {oneLess(j)}
    </>
  );
};

export default OneNote;
