import React from "react";
import { useState } from "react";
import ServerAPI from "../../API/ServerAPI";
import OneButton from "../UI/Buttons/OneButton";
import MyInputGroup from "../UI/MyInputGroup";
import cl from "./diary.module.scss";

const InputNote = ({ note, createNote, less }) => {
  const [val, setVal] = useState(note ? note : { note: "" });
  const [isActive, setOsActive] = useState(false);
  const del = async () => {
    await ServerAPI.deleteNote(note.id);
  };
  return (
    <div onBlur={() => setOsActive(false)}>
      <MyInputGroup
        style={{
          backgroundColor: less === null ? "#F5F5F5" : less.color,
          fontSize: "2rem",
        }}
        size="sm"
        labelstyle={{ fontSize: "2rem" }}
        labelclass={cl.labelmodalLess}
        label={less.subjectName}
        value={val.note}
        onFocus={() => setOsActive(true)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            setOsActive(false);
            createNote(val, less);
          }
        }}
        onChange={(e) => setVal(e.target.value)}>
        {!isActive ? (
          ""
        ) : (
          <OneButton
            title="⮿"
            outline={true}
            className={cl.btn}
            onClick={() => {
              setVal("");
              del();
            }}
          />
        )}
      </MyInputGroup>
    </div>
  );
};

export default InputNote;
