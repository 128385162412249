import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import SchedulesList from "./SchedulesList";
import { useLoading } from "../../hooks/useLoading";
import { BaseAPI } from "../../API/BaseApi";
import NewScheduleBtn from "./EditSchedule/NewScheduleBtn";
import { CSSTransition } from "react-transition-group";

const Schedules = () => {
  const [schedulesList, setSchedulesList] = useState([]);
  const [getlist, isLoading] = useLoading(async () => {
    const list = await BaseAPI.getScheduleAll();
    setSchedulesList(list);
  });

  useEffect(() => {
    getlist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const add = async (listData) => {
    await BaseAPI.createSchedule(listData);
    getlist();
  };
  return (
    <CSSTransition appear={true} in={true} timeout={700} classNames="page">
      <div>
        {" "}
        <div
          className="string_menu"
          style={{ zIndex: "300", position: "fixed" }}>
          <NewScheduleBtn refresh={add} />
        </div>
        <div className="mainImg"> </div>
        <div className="d-flex ms-2 flex-wrap justify-content-center mt-5 ">
          {!isLoading && (
            <SchedulesList list={schedulesList} refresh={getlist} />
          )}{" "}
        </div>
      </div>
    </CSSTransition>
  );
};

export default Schedules;
